<template>
  <template v-for="link in links">
    <template v-if="!link.admin || $store.state.account?.is_staff">

        <template v-if="link.type === 'single'">
          <ul :id="link.label" class="navbar-nav mb-2 mb-lg-0">
            <li v-if="link.to" class="nav-item"><router-link class="nav-link active" :to="link.to" @click="toggleNav()">{{ link.label }}</router-link></li>
            <li v-else class="nav-item">
              <a class="nav-link active" @click="toggleNav()">
                <span v-if="link.account === false">{{ link.label }}</span>
                <span v-else>Hi {{ store.state.account?.first_name }}!</span>
              </a>
            </li>
          </ul>
        </template>
        <template v-else>
          <ul :id="link.label" class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ link.label }}</a>
              <ul class="dropdown-menu">
                <template v-for="dropdown in link.to">
                  <li v-if="dropdown.to"><router-link class="dropdown-item" :to="dropdown.to" @click="toggleNav()">{{ dropdown.label }}</router-link></li>
                  <li v-else><a class="dropdown-item" @click="toggleNav()">{{ dropdown.label }}</a></li>
                </template>
              </ul>
            </li>
          </ul>
        </template>
    </template>
  </template>
</template>

<script setup>
import { useStore } from 'vuex'
const store = useStore()

const props = defineProps({
  links: {
  type: Object
}
})

function toggleNav() {
  try {
    var nav = document.getElementById("navbarToggler");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  } catch {}
};

</script>