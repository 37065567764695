<template>
    <h3 style="text-align: center;">Emergency Contact Numbers</h3>
    <hr>

    <section class='row mb-2'>
        <div class='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6'>
            <div class='card'>
                <div class='card-header'>
                    <h3>Emfuleni Local Municipality (ELM)</h3>
                </div>
                <div class='card-body'>
                    <p>
                        For a list of All ELM emergency contact numbers, go directly to: 
                        Contact Us<!-- , TODO add link -->
                        this link will direct you to the ELM website, 
                        which should be more up-to-date than the list below.
                    </p>
                </div>
            </div>
        </div>
        <div class='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6'>
            <div class='card'>
                <div class='card-header'>
                    <h3>Emfuleni Councillors</h3>
                </div>
                <div class='card-body'>
                    <p>
                        For a list of All ELM Councillors, go to 
                        <a href="https://www.emfuleni.gov.za/index.php/government/speaker/councillors-77309">ELM Councillors’ Contact List</a>
                    </p>
                </div>
            </div>
        </div>
    </section>

    <div class='row'>
        <div class='col-auto mb-2'>
            <section class='card'>
                <div class='card-header'>
                    <h4>Electricity</h4>
                </div>
                <div class='card-body contact'>
                    <ul>
                        <li>016 422 1656</li>
                        <li>016 422 1610</li>
                        <li>016 455 5487</li>
                        <li>016 440 7752</li>
                        <li>080 035 3287</li>
                    </ul>
                </div>
            </section>
        </div>
        <div class='col-auto mb-2'>
            <section class='card'>
                <div class='card-header'>
                    <h4>Electricity Control Room</h4>
                </div>
                <div class='card-body contact'>
                    <ul>
                        <li>016 950 5258</li>
                        <li>016 950 5154</li>
                    </ul>
                </div>
            </section>
        </div>
        <div class='col-auto mb-2'>
            <section class='card'>
                <div class='card-header'>
                    <h4>Traffic Emergencies</h4>
                </div>
                <div class='card-body contact'>
                    <ul>
                        <li>016 422 3600</li>
                    </ul>
                </div>
            </section>
        </div>
        <div class='col-auto mb-2'>
            <section class='card'>
                <div class='card-header'>
                    <h4>SMART Meters</h4>
                </div>
                <div class='card-body contact'>
                    <ul>
                        <li>081 566 0815</li>
                        <li>016 931 0707</li>
                        <li>016 931 0720</li>
                        <li>016 933 9610 (MUSS)</li>
                    </ul>
                </div>
            </section>
        </div>
        <div class='col-auto mb-2'>
            <section class='card'>
                <div class='card-header'>
                    <h4>Sewerage</h4>
                </div>
                <div class='card-body contact'>
                    <ul>
                        <li>016 986 8361</li>
                        <li>016 986 8368</li>
                        <li>016 986 8375</li>
                        <li>082 325 9542</li>

                    </ul>
                </div>
            </section>
        </div>
        <div class='col-auto mb-2'>
            <section class='card'>
                <div class='card-header'>
                    <h4>Water</h4>
                </div>
                <div class='card-body contact'>
                    <ul>
                        <li>016 986 8300</li>
                        <li>016 986 8361</li>
                        <li>016 986 8366</li>
                        <li>016 986 8368</li>
                        <li>016 986 8375</li>
                        <li>016 440 7763</li>
                        <li>016 440 7655</li>
                    </ul>
                </div>
            </section>
        </div>
        <div class='col-auto mb-2'>
            <section class='card'>
                <div class='card-header'>
                    <h4>Illegal Dumping Complaints</h4>
                </div>
                <div class='card-body contact'>
                    <ul>
                        <li>016 986 8440</li>
                    </ul>
                </div>
            </section>
        </div>
        <div class='col-auto mb-2'>
            <section class='card'>
                <div class='card-header'>
                    <h4>Emergencies</h4>
                </div>
                <div class='card-body contact'>
                    <ul>
                        <li><span class="fw-bold">ER 24:</span> 084 124</li>
                        <li><span class="fw-bold">Netcare 911:</span> 082 911</li>
                        <li><span class="fw-bold">Flying Squad:</span> 10111</li>
                        <li><span class="fw-bold">Child Line:</span> 080 005 5555</li>
                        <li><span class="fw-bold">Women Abuse:</span> 080 015 0150</li>
                        <li><span class="fw-bold">Poison:</span> 080 033 444</li>
                        <li><span class="fw-bold">Fire & Rescue:</span> 016 430 1855</li>
                        <li><span class="fw-bold">VEC:</span> 016 976 7887 / 073 370 6995</li>
                    </ul>
                </div>
            </section>
        </div>
        <div class='col-auto mb-2'>
            <section class='card'>
                <div class='card-header'>
                    <h4>Crime</h4>
                </div>
                <div class='card-body contact'>
                    <ul>
                        <li><span class="fw-bold">Sector 1 (CW & NW7):</span> 071 675 7239</li>
                        <li><span class="fw-bold">Sector 2 (CE & CBD):</span> 071 675 7241</li>
                        <li><span class="fw-bold">Sector 3:</span> 071 675 7243</li>
                        <li><span class="fw-bold">Sector 4:</span> 071 675 7245</li>
                        <li><span class="fw-bold">Sector 5:</span> 071 675 7250</li>
                        <li><span class="fw-bold">Relief Commander:</span> 071 675 7246</li>
                        <li><span class="fw-bold">Vanderbijlpark SAPS:</span> 016 910 9046/7</li>
                        <li><span class="fw-bold">SAPS Crime Stop:</span> 086 001 0111</li>

                    </ul>
                </div>
            </section>
        </div>
        <div class='col-auto mb-2'>
            <section class='card'>
                <div class='card-header'>
                    <h4>Snakebite Emergencies</h4>
                </div>
                <div class='card-body contact'>
                    <ul>
                        <li><span class="fw-bold">Tygerberg Poison Centre:</span> 086 155 5777</li>
                        <li><span class="fw-bold">Johan Marais:</span> 082 494 2039</li>
                        <li><span class="fw-bold">Heidi Pfeifer:</span> 083 374 7087</li>
                        <li><span class="fw-bold">Dr Jenna Taylor:</span> 083 631 4816</li>
                        <li><span class="fw-bold">Dr Christoff Bell:</span> 073 174 0199</li>
                        <li><span class="fw-bold">Dr PJC Buys (Namibia):</span> +264 81 127 5109</li>
                    </ul>
                </div>
            </section>
        </div>
    </div>
</template>

<style>
    .contact {
        margin: 0 0 0 0; 
        padding: 10px 20px 0 0;
    }
    ul {
        margin-bottom: 10px;
    }
</style>