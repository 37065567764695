<template>
  <section id="loginForm" class="centered-content">
    <div class='card shadow-lg'>
      <div class='card-header'>
        <h3>Use a local account to log in.</h3>or proceed to the <router-link :to="{ name: 'registration' }">registration
          page</router-link> if you want to create a new account.
      </div>
      <form @submit.prevent="submitForm">
        <div class='card-body'>
          <div class="mb-3">
            <label for="id_email" class="form-label">Email</label>
            <input id="id_email" autocomplete="username" type="text" class="form-control" v-model="email"
              placeholder="email" />
          </div>

          <label for="password" class="form-label">Password</label><br>
          <div class="input-group pb-3">
            <input :type="inputPassword" class="form-control" v-model="password" aria-label="password"
              placeholder="password">
            <span class="input-group-text material-icons" @click="toggleShow">{{ icon }}</span>
          </div>
          <div class="right-content">
            <router-link :to="{ name: 'ForgotPassword' }">Forgot Password?</router-link>
          </div>
        </div>
        <div class='card-footer'>
          <div class="field">
            <div class="control">
              <button class="btn btn-primary w-100">Log in</button>
            </div>
          </div>
          <ul v-if="(error && error.length > 0)">
            <li for="password" class="form-label text-danger col-12" v-for="e in error">
              {{ e }}</li>
          </ul>
        </div>
      </form>
    </div>
  </section>

  <section class="mt-5">
    <RegisterDisclaimer />
  </section>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import RegisterDisclaimer from '../Core/RegisterDisclaimer'

const store = useStore();
const router = useRouter();
const route = useRoute();

let email = ref();
let password = ref();
let error = ref([]);
let inputPassword = ref('password');
let icon = ref('visibility');

onMounted(async () => {
  try { email.value = route.query.email; } catch { }
})

async function submitForm() {

  const formData = {
    email: email.value,
    password: password.value,
  }

  const payload = {
    api: '/auth/token/login/',
    data: formData
  }

  const response = await store.dispatch('post', payload);

  if (response.status == 200) {
    await store.commit('setTokens', response.data.auth_token);
    await store.dispatch('getProfile');

    if (store.state.profileCompleted) {
      router.push({ name: 'home' });
      return;
    }

    router.push({ name: 'profile' });
  }

  if (response.status == 400) {
    const keys = Object.keys(response.data);
    let errors = [];

    keys.forEach(key => {
      const prop = response.data[key];

      if (Array.isArray(prop)) {
        prop.forEach(element => {
          errors.push(element);
        });
      }
    });

    error.value = errors;
    return;
  }

  error.value = ['Unexpected error occurred, please contact the administrator'];
  console.log(response);
}

function toggleShow() {
  inputPassword.value = inputPassword.value === 'text' ? 'password' : 'text';
  icon.value = inputPassword.value === 'text' ? 'visibility_off' : 'visibility';
}
</script>
