<template>
    <div class="centered-content">
        <h3>Telegram</h3>
        <img src="./telegram-logo150-ERPA.png">
    </div>
    <hr>
    <div>
        <h3>Telegram Groups</h3>
        <p>
            The groups below are maintained and supported by ERPA and are more or less specialized in terms of their individual purposes. 
            If you believe that you could benefit by joining the groups relevant to you, please go ahead and do so right away 
            (just click on the image), but be sure to join the ‘umbrella’ group, the ERPA Community, first.
        </p>
        <p>
            In the meantime, if you have not already done so, you are welcome to download and install Telegram on your devices so long;
        </p>
        <p>
            Apple Users can get Telegram for Mac, iPhone and iPad from the iTunes App Store (iOS 8 and later):<br>
            <a href="https://itunes.apple.com/za/app/telegram-messenger/id686449807?mt=8">
                https://itunes.apple.com/za/app/telegram-messenger/id686449807?mt=8
            </a>
        </p>
        <p>Android Users can get Telegram from the Google Play Store (Android 4 and later):<br>
            <a href="https://play.google.com/store/apps/details?id=org.telegram.messenger">
                https://play.google.com/store/apps/details?id=org.telegram.messenger
            </a>
        </p>
        <p>Telegram can also be installed on your Personal Computer (Win7 x64, x86 and later):<br>
            <a href="https://desktop.telegram.org/">https://desktop.telegram.org/</a>
        </p>
        <p>See you 'on the other side'…😊.</p>
        <hr>
    </div>
    <div class="centered-content">
        <p style="text-align: center;">
            The ERPA Community Group  covers all community members and groups, so be sure to join this group first, 
            so that you don’t miss out on relevant communication that may not be covered by the suburb-groups below.
        </p>
        <br>
        <div class='row'>
            <div class='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                <a href="https://t.me/se3_community" target="_blank"><img src="./SE03_Community.png"></a>
            </div>
            <div class='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                <a href="https://t.me/se3_traders" target="_blank"><img src="./SE03_Trader.jpg"></a>
            </div>
            <div class='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                <a href="https://t.me/joinchat/La2v90UorWhwrFjt2T2TFQ" target="_blank"><img src="./SE03_Crime.png"></a>
            </div>
        </div>
    </div>

</template>

<style scoped>
    img {
        max-width: 100%;
    }
</style>