<template>
    <div class="row centered-content">
        <div v-if="poll">
            <div class='card shadow-lg'>
                <div class='card-header'>
                    <div class='row'>
                        <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                            <h3 class="col-6 col-sm-6 col-md-6 col-lg-8 col-xl-8 col-xxl-8">{{ poll.title }}</h3>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div class="d-block d-sm-block d-md-block d-lg-flex d-xl-flex d-xxl-flex">
                                <h6 class="ms-auto" style="font-size: 12px;">Date created: {{
                                        $filters.date(poll.date_created)
                                }} </h6>
                            </div>
                            <div class="d-block d-sm-block d-md-block d-lg-flex d-xl-flex d-xxl-flex">
                                <h6 class="ms-auto" style="font-size: 12px;">Created By: {{
                                        poll.created_by
                                }} </h6>
                            </div>
                        </div>
                        <div class="col-12">
                            <div id="editor"></div>
                        </div>
                    </div>
                    <small v-if="!poll.active">This poll is currently inactive, but you can still view the results</small>
                </div>
                <template v-if="!store.state.account || !poll.active || poll.has_voted">
                    <div class='card-body' style="padding:10px" v-for="q in poll.questions">
                        <h5>{{ q.question }}</h5>
                        <div style="padding:5px" v-for="a in q.answers">
                            <label>{{ a.answer }}</label>
                            <small>Votes: {{ a.votes }}</small>
                            <div class="progress">
                                <div class="progress-bar bg-primary" role="progressbar"
                                    :style="'width:' + a.votes / q.total_votes * 100 + '%'" :aria-valuenow="a.votes"
                                    aria-valuemin="0" :aria-valuemax="q.total_votes">
                                    {{(a.votes / q.total_votes * 100).toFixed(1) + '%'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!store.state.account && poll.active" class="card-footer">
                        <p>Please log in to participate in poll</p>
                    </div>
                </template>
                <form v-else @submit.prevent="submitForm">
                    <div class='card-body'>
                        <div v-for="q, idx in poll.questions" class='row'>
                            <hr v-if="idx > 0">
                            <div class='col-12'>
                                <h3>{{ q.question }}</h3>
                            </div>
                            <div class="col-12">
                                <div class="d-block">
                                    <template v-for="a in q.answers">
                                        <input :name="'q-' + q.id" :id="'q-' + q.id + '-a-' + a.id" type="radio"
                                            :value="a.id" v-model="q.answer" required>
                                        <label :for="'q-' + q.id + '-a-' + a.id" class="ms-1 form-label">{{ a.answer
                                        }}</label><br>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex">
                        <button class="btn btn-primary ms-auto">Submit</button>
                    </div>
                </form>
            </div>
        </div>
        <div v-else>
            <div class='card shadow-lg'>
                <div class='card-header'>
                    <div class='row'>
                        <h3>Could not find poll</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
small {
    color: #808080;
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

const store = useStore();
const route = useRoute();
const router = useRouter();
const toast = useToast();

let slug = route.params.slug ? route.params.slug : undefined
let poll = ref(undefined);

onMounted(async () => {
    await GetPoll();
    createQuill('#editor', JSON.parse(poll.value.body));
})

async function GetPoll() {
    if (slug) {
        const response = await store.dispatch(
            'get', 
            store.state.account && store.state.account.is_staff ? `polls/${slug}/` : `polls/public/${slug}/`
            );
        console.log(response);
        if (response.status === 200) {
            // make sure that only one poll is returned and not an array of polls
            if (!Array.isArray(response.data)) {
                poll.value = response.data;
                return;
            }
        }

        if (response.status === 404) {

            toast.error(`Poll '${slug}' was not found`, {
                timeout: 3000
            });

            router.push({ name: 'listPolls' });
            return;
        }

        toast.error(`Unexpected error getting ${slug}: ${response.status}`, {
            timeout: 3000
        });
        router.push({ name: 'polls' });
        console.log(response.data);
    }
}

async function submitForm() {
    if (!store.state.account) {
        toast.error('Only logged in users can participate', {
            timeout: 3000
        });
        return;
    }

    const payload = {
        api: `polls/answer/${slug}/`,
        data: poll.value
    }

    const response = await store.dispatch('post', payload);

    if (response.status == 200) {
        toast.success(`Successfully Voted`, {
            timeout: 3000
        });
        await GetPoll();
        return;
    }

    if (response.status == 400) {
        toast.error(`Please complete the form`, {
            timeout: 3000
        });
        await GetPoll();
        return;
    }

    if (response.status == 403) {
        toast.error(response.data, {
            timeout: 3000
        });
        await GetPoll();
        return;
    }

    if (response.status == 409) {
        toast.error(`Already Voted`, {
            timeout: 3000
        });

        await GetPoll();
        return;
    }
}

function createQuill(el, contents) {
  let quill = new Quill(el, {
    modules: { toolbar: false, },
    readOnly: true,
  });

  quill.setContents(contents);
}

</script>