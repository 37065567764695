import { Modal } from 'bootstrap'

export function showModal(title, description, yesScenario, noScenario, callback) {

    const modals = document.querySelectorAll('.modal');

    if (modals) {
        modals.forEach(m => {
            m.parentElement.remove();
        });
    }

    const wrapper = document.createElement('div');
    wrapper.innerHTML = `
        <div class="modal fade" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${title}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>${description}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">${noScenario}</button>
                        <button type="button" class="btn btn-primary modal-success-btn" data-bs-dismiss="modal">${yesScenario}</button>
                    </div>
                </div>
            </div>
        </div>`;

    wrapper.querySelector('.modal-success-btn').onclick = callback;

    document.body.append(wrapper);

    const modal = new Modal(wrapper.querySelector('.modal'));
    modal.show();
};