<template>
  <div class="centered-content">
    <h3>Profile Update Successful</h3>
  </div>
  <hr>
  <p>You have successfully updated your User Profile.</p>
  <p>This page will automatically return to your profile page in 5 seconds. Click 
    <router-link :to="{name:'profile'}">here</router-link>
    to return to your profile.
  </p>
</template>

<style scoped>

</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'

const store = useStore()
const router = useRouter()
const route = useRoute()
const toast = useToast()

onMounted(async () => {
  let timeout = 5000
  setTimeout(() => {
    router.push({name: 'profile'})
  }, timeout);
})
</script>