<template>
    <div>
        <h3 style="text-align: center;">Documents
            <router-link v-if="store.state.account && store.state.account.is_staff" class="ms-auto"
                :to="{ name: 'createDocument' }">
                <span class="material-icons text-success" style="cursor: pointer;">post_add</span>
            </router-link>
        </h3>
    </div>
    <hr>
    <div class='row centered-content'>
        <div class='col-auto '>
            <div class="centered-content">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Document</th>
                            <th>Contact Info</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="document in documents">
                            <tr>
                                <td>{{ document.title }}</td>
                                <td>
                                        <div v-if="document.contact_name">
                                            <small>Name: {{ document.contact_name }}</small>
                                        </div>
                                        <div v-if="document.email">
                                            <small>Email: {{ document.email }}</small>
                                        </div>
                                        <div v-if="document.phone_number">
                                            <small>Phone Number: {{ document.phone_number }}</small>
                                        </div>
                                        <br>
                                </td>
                                <td>
                                    <span v-if="document" @click="downloadDoc(document)"
                                        class="material-icons material-symbols-outlined p-1">
                                        download
                                    </span>
                                    <span v-if="store.state.account && store.state.account.is_staff"
                                        class="material-icons text-danger p-1"
                                        @click="showModal('Delete Document', 'Are you sure you want to delete this document?', 'Yes', 'No', () => deleteDocument(document))">
                                        delete</span>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { showModal } from '../../components/customModal.js'

const store = useStore();

let documents = ref(undefined);

onMounted(async () => {
    await getDocuments();
})

async function getDocuments() {
    const response = await store.dispatch('get', 'documents/public/');

    if (response.status == 200) {
        documents.value = response.data;
    }
}

async function downloadDoc(doc) {
    if (doc) {
        const response = await store.dispatch('download', `documents/download/${doc.slug}/`);

        if (response.status == 200) {
            const element = document.createElement('a');
            element.setAttribute('href', window.URL.createObjectURL(response.data));
            element.setAttribute('download', doc.file_name);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    }
}


async function deleteDocument(document) {
    if (document) {
        const response = await store.dispatch('delete', `documents/${document.slug}/`);

        if (response.status == 202) {
            await getDocuments();
        }
    }
}
</script>