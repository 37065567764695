<template>
  <div class='row'>
    <div class='col-12 centered-content'>
      <div id="create" class='card'>
        <div class='card-header d-flex'>
          <h3>New Document</h3>
        </div>
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class='card-body'>
            <div class="mb-3">
              <label for="title" class="form-label">Document Title</label>
              <input id="title" type="text" class="form-control" v-model="doc.title" />
              <small v-if="errors && errors.title" v-for="error in errors.title" for="title"
                class="form-label text-danger">{{ error }}</small>
            </div>
            <div class="mb-3">
              <label for="contact_name" class="form-label">Contact name</label>
              <input id="contact_name" type="text" class="form-control" v-model="doc.contact_name" />
              <small v-if="errors && errors.contact_name" v-for="error in errors.contact_name" for="contact_name"
                class="form-label text-danger">{{ error }}</small>
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input id="email" type="text" class="form-control" v-model="doc.email" />
              <small v-if="errors && errors.email" v-for="error in errors.email" for="email"
                class="form-label text-danger">{{ error }}</small>
            </div>
            <div class="mb-3">
              <label for="phone_number" class="form-label">Phone Number</label>
              <input id="phone_number" type="text" class="form-control" v-model="doc.phone_number" />
              <small v-if="errors && errors.phone_number" v-for="error in errors.phone_number" for="phone_number"
                class="form-label text-danger">{{ error }}</small>
            </div>
            <div id="document_uploader" class="mb-3">
              <label for="document" class="form-label">Document</label><br>
              <input id="document" @change="storeDocument" class="form-control" type="file" accept="*">
              <small v-if="errors && errors.document" v-for="error in errors.document" for="editor"
                class="form-label text-danger">{{ error }}</small>
            </div>
          </div>
          <div class='card-footer'>
            <button class="btn btn-primary w-100">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  font-weight: bold;
}
</style>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

const store = useStore();
const router = useRouter();
const toast = useToast();

let doc = ref({});
let errors = ref(undefined);

async function submitForm() {
  let formData = new FormData();
  formData.append("title", doc.value.title);

  if (doc.value.contact_name) {
    formData.append("contact_name", doc.value.contact_name);
  }
  
  if (doc.value.email) {
    formData.append("email", doc.value.email);
  }

  if (doc.value.phone_number) {
    formData.append("phone_number", doc.value.phone_number);
  }

  if (doc.value.document) {
    formData.append("document", doc.value.document);
  }

  const payload = {
    api: '/documents/',
    data: formData
  }

  const response = await store.dispatch('post', payload);

  if (response.status == 201) {
    toast.success(`Document uploaded`, {
      timeout: 5000
    });
    router.push({ name: 'listDocument' });
  }
  else {
    errors.value = response.data;
  }
}

function storeDocument(event) {
  doc.value.document = event.target.files[0];
}

</script>