<template>
    <section class="centered-content">
        <div class='card shadow-lg'>
            <div v-if="reset_password">
                <div class='card-header'>
                    <h3>Enter your new password</h3>
                </div>
                <form @submit.prevent="submitNewPassword">
                    <div class='card-body'>
                        <label for="password" class="form-label">Password</label><br>
                        <div class="input-group pb-3">
                            <input :type="inputPassword" class="form-control" v-model="new_password"
                                aria-label="password" placeholder="Please provide a strong password">
                            <span class="input-group-text material-icons" @click="toggleShow">{{ icon }}</span>
                        </div>

                        <label for="password2" class="form-label">Confirm Password</label><br>
                        <div class="input-group">
                            <input :type="inputPassword" class="form-control" v-model="re_new_password"
                                aria-label="password2" placeholder="Retype Password">
                            <span class="input-group-text material-icons" @click="toggleShow">{{ icon }}</span>
                        </div>

                        <small v-if="error" class="form-label text-danger">{{ error }}</small>
                        <ul v-if="errors && errors.uid">
                            <li v-for="e in errors.uid" class="form-label text-danger">{{ e }}</li>
                        </ul>

                        <ul v-if="errors && errors.new_password">
                            <li v-for="e in errors.new_password" class="form-label text-danger">{{ e }}</li>
                        </ul>
                    </div>
                    <div class='card-footer'>
                        <div class="field">
                            <div class="control">
                                <button class="btn btn-primary w-100">Reset</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div v-else>
                <div class='card-header'>
                    <h3>Recover your account</h3>or proceed to the <router-link
                        :to="{ name: 'registration' }">registration
                        page</router-link> if you want to create a new account.
                </div>
                <form @submit.prevent="submitEmail">
                    <div class='card-body'>
                        <div class="mb-3">
                            <label for="id_email" class="form-label">Email</label>
                            <input id="id_email" autocomplete="username" type="text" class="form-control"
                                v-model="email" placeholder="email" />
                            <small v-if="error" class="form-label text-danger">{{ error }}</small>
                        </div>
                    </div>
                    <div class='card-footer'>
                        <div class="field">
                            <div class="control">
                                <button class="btn btn-primary w-100">Send Email</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

const toast = useToast();
const store = useStore();
const router = useRouter();
const route = useRoute();

let inputPassword = ref('password');
let icon = ref('visibility');
let reset_password = ref(false);
let email = ref('');
let new_password = ref('');
let re_new_password = ref('');
let error = ref('');
let errors = ref({});

onMounted(async () => {
    if (route.params.uid && route.params.token) {
        reset_password.value = true;
    }
});

async function submitEmail() {
    error.value = null;
    const formData = {
        email: email.value
    }

    const payload = {
        api: '/auth/users/reset_password/',
        data: formData
    }

    const response = await store.dispatch('post', payload);
    console.log(response);

    if (response.status == 204) {
        toast.success('Successfully sent email', {
            timeout: 3000
        });
        router.push({
            name: 'home'
        });

        return;
    }

    // email address not found in database
    if (response.status == 400) {
        error.value = 'Email address was not found';
        return;
    }

    error.value = 'Unexpected error occurred, please contact the administrator';
    console.log(response);
}

async function submitNewPassword() {
    error.value = null;
    errors.value = null;

    if (new_password.value === re_new_password.value) {
        const formData = {
            uid: route.params.uid,
            token: route.params.token,
            new_password: new_password.value,
            re_new_password: re_new_password.value,
        }

        const payload = {
            api: '/auth/users/reset_password_confirm/',
            data: formData
        }

        const response = await store.dispatch('post', payload);
        console.log(response);

        if (response.status == 204) {
            toast.success('Successfully reset password', {
                timeout: 3000
            });
            router.push({
                name: 'login'
            });

            return;
        }

        if (response.status == 400) {
            errors.value = response.data;
            return;
        }

        error.value = 'Unexpected error occurred, please contact the administrator';
        console.log(response);
    }
    else {
        error.value = 'Password provided must match.';
    }
}

function toggleShow() {
    inputPassword.value = inputPassword.value === 'text' ? 'password' : 'text';
    icon.value = inputPassword.value === 'text' ? 'visibility_off' : 'visibility';
}
</script>
