<template>
    <div class="centered-content">
        <h3>{{ id ? 'Update' : 'Create' }} New Dispute Criteria</h3>
    </div>
    <hr>

    <form @submit.prevent="submitForm" enctype="multipart/form-data" class="card card-body">
        <div class="mb-3">
            <label for="type" class="form-label">Dispute Type</label>
            <select id="type" v-model="criteria.dispute_type" class="form-select">
                <option v-for="t in types" :value="t.dispute_type">{{ t.dispute_type }}</option>
            </select>
            <small v-if="(errors && errors.dispute_type)" class="form-label text-danger col-12"
                v-for="error in errors.dispute_type">
                {{ error }}</small>
        </div>
        <div class="mb-3">
            <label for="header" class="form-label">Header</label>
            <input id="header" type="text" class="form-control" v-model="criteria.header" placeholder="header" />
            <label v-if="errors && errors.header" v-for="error in errors.header" for="header"
            class="form-label text-danger">{{ error }}</label>
        </div>
        <div class="mb-3">
            <label for="editor" class="form-label">Editor</label>
            <div id="editor"></div>
            <label v-if="errors && errors.body" v-for="error in errors.body" for="editor"
            class="form-label text-danger">{{ error }}</label>
        </div>

        <div id="image_uploader" class="mb-3">
            <label for="bank_confirmation" class="form-label">Upload Bank Confirmation</label><br>
            <input id="bank_confirmation" @change="storeBank" class="form-control" type="file" accept="application/pdf">

            <label v-if="errors && errors.bank_confirmation" v-for="error in errors.bank_confirmation" for="bank_confirmation"
            class="form-label text-danger">{{ error }}</label>
        </div>
        <button class="btn btn-primary">Submit</button>
    </form>
</template>

<style scoped>

</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'

const store = useStore()
const router = useRouter()
const route = useRoute()
const toast = useToast()

let types = ref([])
let criteria = ref({
    dispute_type: 'undefined',
    header: 'undefined',
    body: 'undefined',
    bank_confirmation: undefined,
})
let errors = ref(undefined)

let id = route.params.id ? route.params.id : undefined

onMounted(async () => {
    await getDisputeTypes()
    if (id) {
        await getType()
    }
    createQuill();
})

async function getDisputeTypes() {
    const response = await store.dispatch('get', 'disputes/types/');
    console.log(response);
    if (response.status === 200) {
        types.value = response.data;
    }
    else {
        errors.value = response.data
    }
}

async function getType(){ 
    const response = await store.dispatch('get', `disputes/criteria/${id}/`)
    
    if (response.status === 200){
        criteria.value = response.data
        criteria.value.body = JSON.parse(criteria.value.body);
        criteria.value.bank_confirmation = undefined
    }
    else {
        toast.error(`Dispute Criteria not found`, {timeout: 5000});
    }
}

function createQuill() {
  var quill = new Quill('#editor', {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['link'],
        ['image'],
        ['video'],
        ['clean'],                                       // remove formatting button
      ],
      imageResize: {},
      videoResize: {},
    },
    theme: 'snow'
  });

  var toolbar = quill.container.previousSibling;
  toolbar.querySelector('button.ql-bold').setAttribute('title', 'bold')
  toolbar.querySelector('button.ql-italic').setAttribute('title', 'italic')
  toolbar.querySelector('button.ql-underline').setAttribute('title', 'underline')
  toolbar.querySelector('button.ql-strike').setAttribute('title', 'strike')
  toolbar.querySelector('button.ql-blockquote').setAttribute('title', 'blockquote')
  toolbar.querySelector('button.ql-code-block').setAttribute('title', 'code')
  toolbar.querySelector('button.ql-header').setAttribute('title', 'header')
  toolbar.querySelector('button.ql-list').setAttribute('title', 'list')
  toolbar.querySelector('button.ql-script').setAttribute('title', 'script')
  toolbar.querySelector('button.ql-indent').setAttribute('title', 'indent')
  toolbar.querySelector('button.ql-direction').setAttribute('title', 'direction')
  toolbar.querySelector('button.ql-header').setAttribute('title', 'header')
  toolbar.querySelector('button.ql-link').setAttribute('title', 'link')
  toolbar.querySelector('button.ql-image').setAttribute('title', 'image')
  toolbar.querySelector('button.ql-video').setAttribute('title', 'video')
  toolbar.querySelector('button.ql-clean').setAttribute('title', 'clean')

  try {
    quill.setContents(criteria.value.body);
  }
  catch { }
}

function storeBank(event) {
  criteria.value.bank_confirmation = event.target.files[0];
}

async function submitForm() {
  var linkBlot = Quill.find(document.querySelector('#editor'));
  criteria.value.body = JSON.stringify(linkBlot.getContents().ops);

  let formData = new FormData();
  formData.append("dispute_type", criteria.value.dispute_type);
  formData.append("header", criteria.value.header);
  formData.append("body", criteria.value.body);
  if (criteria.value.bank_confirmation && criteria.value.bank_confirmation != '') {
    formData.append("bank_confirmation", criteria.value.bank_confirmation);
  }
  let method = '';
  let url = '/disputes/criteria/';
  if (id) {
    //update
    method = 'put';
    url += `${id}/`;
    formData.append("id", id);
  }
  else {
    //new
    method = 'post';
  }
  const payload = {
    api: url,
    data: formData
  };

  const response = await store.dispatch(method, payload);
  if (response.status === 201 || response.status === 202) {
    toast.success(`Criteria created`, {
      timeout: 5000
    });
    router.push({ name: 'ListCriteria' });
  }
  else {
    errors.value = response.data;
  }
}
</script>