import Profile from './Profile.vue'
import UpdateSuccess from './UpdateSuccess.vue'
import ForgotPassword from './ForgotPassword.vue'

const accountRoutes = [
    { path: '/profile', name: 'profile', component: Profile, meta: { title: 'Profile', auth: true } },
    { path: '/profile/updated', name: 'UpdateProfileSuccess', component: UpdateSuccess, meta: { title: 'Update Success', auth: true } },
    { path: '/password/reset/:uid?/:token?', name: 'ForgotPassword', component: ForgotPassword, meta: { title: 'Forgot Password', auth: false } },
]

export default accountRoutes