<template>
    <section>
        <h1 class="text-center">About ERPA</h1>
        <hr>
    </section>
    <section id="history">
        <h3 class="text-center">History</h3>
        <p>
            The Emfuleni Ratepayers' Association (ERPA) is an
            <a href="https://www.collinsdictionary.com/dictionary/english/apolitical" target="_blank">
                apolitical,
            </a>
            <a href="https://www.polity.org.za/article/non-profit-companies-registering-as-a-public-benefit-organisation-and-the-benefits-that-accompany-such-status-2017-04-18"
                target="_blank">
                non-profit company
            </a>
            that was constituted in 2010 (under the name Emfuleni Rate Payers Union),
            which was changed to Emfuleni Ratepayers Association, with registration number 2010/015918/08.
        </p>
        <p>
            The ERPA enterprise grew rapidly since March 2018,
            when new committee members entered the fray,
            with a view to setting up a public/private partnership (PPP) with the Emfuleni local municipality,
            aiming to improve the state of critical municipal services and infrastructure in Emfuleni.
            Initial focus was on refuse removal. However, momentum dramatically increased due to media exposure of the
            dismal state of the Vaal River eco-system,
            being polluted with raw sewerage flowing directly into the river from trenches dug by the Emfuleni local
            municipality,
            as a result of defective sewerage plants. The media flocked to the area, providing significant coverage of
            the problem.
            ERPA had to scramble in order to contain the mounting discontent of ratepayers.
        </p>
        <p>
            Several informal public meetings were held in order to make the community aware of the existence of the
            ERPA,
            culminating into a formal meeting on 6 September 2018 at the Stonehaven on Vaal Restaurant and Business
            Center,
            introducing the full ERPA Committee and its Vision and Mission statements.
        </p>
    </section>
    <hr>
    <section class="mb-5">
        <h3 class="text-center">Management Committee</h3>
        <p>In terms of the ERPA Constitution, the Management Committee has been established as follows:</p>
        <div class="w-auto centered-content">
            <img src="./ManagementCommittee_20190825.jpg" style="max-width: 100%;">
        </div>
    </section>
    <div class="mt-5" v-if="store.state.isAuthenticated == false">
        <RegisterNow />
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import RegisterNow from './RegisterNow.vue'

const store = useStore();
</script>