<template>
    <div class="centered-content">
        <template v-if="store.state.isAuthenticated">
            <div v-if="!store.state.profileCompleted" class="centered-content">
                <div>Please complete profile to submit a complaint</div>
                <router-link to="/profile" custom v-slot="{ navigate }">
                    <button class="btn btn-primary w-100" @click="navigate" @keypress.enter="navigate"
                        role="link">Update Profile</button>
                </router-link>
            </div>
            <form v-else @submit.prevent="submitForm" enctype="multipart/form-data">
                <div id="t&c's">
                    <p class="p-0 m-0 fw-bold">Please check box to confirm the following:</p>
                    <ul class="ms-2 ps-2">
                        <li>You have read and understood the appropriate <router-link
                                :to="{ name: 'legislation' }">legislation</router-link></li>
                        <li>You accept the terms & conditions as set out in our <router-link
                                :to="{ name: 'policies' }">policies</router-link>.</li>
                    </ul>
                    <div class="mb-3 form-check">
                        <input id="accept" type="checkbox" class="form-check-input" v-model="terms"
                            placeholder="title" />
                        <label for="accept" class="form-check-label">
                            I, {{ store.state.account.first_name }} {{ store.state.account.last_name }}, accept the
                            terms &
                            conditions
                        </label>

                    </div>
                </div>
                <div v-if="terms">
                    <h3>Lodge Dispute</h3>
                    <hr>
                    <div id="type" class="mb-3">
                        <label for="type" class="form-label">Dispute Type</label>
                        <select id="type" v-model="dispute.dispute_type" class="form-select">
                            <option v-for="t, idx in types" :value="idx">{{ t.dispute_type }}</option>
                        </select>
                        <small v-if="(errors && errors.dispute_type)" class="form-label text-danger col-12">
                            Dispute Type is Required
                        </small>
                    </div>

                    <template v-if="types[dispute.dispute_type]?.criteria.length > 0">
                      <label class="form-check-label">Select the reason/s for lodging this Billing Dispute</label>
                      <template v-for="crit, idx in types[dispute.dispute_type]?.criteria">
                        <div class="mb-1 form-check">
                          
                          <label :for="crit.header" class="form-check-label">{{crit.header}}</label>
                          <input 
                            :id="crit.header" type="checkbox" class="form-check-input" 
                            v-model="dispute.criteria" 
                            :value="crit.id" 
                          />
                        </div>
                      </template>
                    </template>

                    <template id="displayCriteria" v-if="dispute?.criteria?.length > 0 && dispute?.dispute_type >= 0">
                      <template v-for="crit, idx in types[dispute.dispute_type]?.criteria">
                        <template v-if="dispute.criteria.includes(crit.id)">
                          <!-- quill -->
                            <div v-if="crit.body" :id="'crit_body_'+idx" class="left-content quill-container ql-editor quill-wrapper ">
                              {{ readOnlyQuill('crit_body_'+idx, crit.body) }}
                            </div>
                            <!-- download pdf -->
                            <a v-if="crit.bank_confirmation" :href="crit.bank_confirmation" :download="crit.dispute_type + ' ' + crit.header + 'pop.pdf'" >
                                Download Bank Confirmation
                            </a>
                        </template>
                      </template>
                    </template>

                    <div id="accountNumber" class="mb-3">
                        <label for="account_number" class="form-label">Account Number</label>
                        <input id="account_number" v-model="store.state.account.municipal_account_number" type="text"
                            class="form-control" aria-describedby="account_numberHelp" readonly>
                        <div id="account_numberHelp" class="form-text">This is the account reflected on your profile,
                            please ensure it is correct</div>
                    </div>
                    <div id="amount" class="mb-3">
                        <label for="amount" class="form-label">Dispute Amount</label>
                        <input id="amount" v-model="dispute.amount_disputed" type="text" class="form-control" v-maska
                            data-maska="R0.99" data-maska-tokens="0:\d:multiple|9:\d:optional"
                            aria-describedby="amountHelp" @keypress="isNumber($event)">
                        <div id="amountHelp" class="form-text">The amount that you want to dispute in Rands and Cents
                            (eg. R123.45)</div>
                        <small v-if="(errors && errors.amount_disputed)" class="form-label text-danger col-12"
                            v-for="error in errors.amount_disputed">
                            {{ error }}</small>
                    </div>
                    <div id="reason" class="mb-3">
                        <label for="Reason" class="form-label">Reason</label>
                        <textarea id="Reason" v-model="dispute.reason" type="text" class="form-control" rows="8"
                            maxlength="1000" placeholder="Please provide the reason for your dispute here"></textarea>
                        <small v-if="(errors && errors.reason)" class="form-label text-danger col-12"
                            v-for="error in errors.reason">
                            {{ error }}</small>
                    </div>
                    <div id="statement" class="mb-3">
                        <label for="statement" class="form-label">Upload Statement</label>
                        <input id="statement" @change="storeStatement" class="form-control" type="file"
                            accept="application/pdf">
                        <small v-if="(errors && errors.statement)" class="form-label text-danger col-12"
                            v-for="error in errors.statement">
                            {{ error }}</small>
                    </div>
                    <div id="pop" class="mb-3">
                        <label for="pop" class="form-label">Upload Proof of Payment</label>
                        <input id="pop" @change="storePOP" class="form-control" type="file" accept="application/pdf">
                        <small v-if="(errors && errors.proof_of_payment)" class="form-label text-danger col-12"
                            v-for="error in errors.proof_of_payment">
                            {{ error }}</small>
                    </div>

                    <button type="submit" class="btn btn-primary w-100 mt-3">Submit</button>
                </div>
            </form>
        </template>
    </div>
</template>

<style scoped>
    .quill-container {
        width: auto !important;
        max-width: 100% !important;
        box-sizing: border-box !important;
        overflow-x: auto !important;
    }

    .ql-editor {
        word-wrap: break-word !important;
        white-space: normal !important;
    }
    .quill-wrapper {
        width: 100% !important;
        padding: 10px !important; /* Add padding if needed */
    }
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { vMaska } from 'maska'
import { readOnlyQuill } from './../../components/quillManager'

const store = useStore()
const router = useRouter()
const toast = useToast()

let terms = ref(false)
let types = ref([]);
let statement = ref(undefined);
let pop = ref(undefined);
let dispute = ref({
    account_number: store.state.account.municipal_account_number,
    dispute_type: 0,
    amount_disputed: 'R',
    reason: '',
    criteria: [],
});

let errors = ref({});

onMounted(async () => {
    await getDisputeForm();
})

async function getDisputeForm() {
    const response = await store.dispatch('get', 'disputes/types/');
    if (response.status === 200) {
        types.value = response.data;
    }
}

async function submitForm() {
    errors.value = {};
    let formData = new FormData();

    const amount = parseFloat(dispute.value.amount_disputed.replace('R', ''))
    if (dispute.value.dispute_type >= 0) {formData.append("dispute_type", types.value[dispute.value.dispute_type].dispute_type)}
    formData.append("account_number", store.state.account.municipal_account_number);
    if (amount) {formData.append("amount_disputed", amount)}
    formData.append("reason", dispute.value.reason);
    if (dispute.value.statement) {formData.append("statement", dispute.value.statement)}
    if (dispute.value.proof_of_payment) {formData.append("proof_of_payment", dispute.value.proof_of_payment)}
    if (dispute.value.criteria.length > 0) {formData.append("criteria", dispute.value.criteria) }

    const payload = {
        api: 'disputes/lodge/',
        data: formData
    }
    const response = await store.dispatch('post', payload);
    if (response.status == 201) {
        toast.success('Successfully created dispute', {
            timeout: 3000
        });
        router.push({ name: 'detailDispute', params: { id: response.data.id } });
        return;
    }

    if (response.status == 400) {
        errors.value = response.data;
        return;
    }

    toast.error(`Error creating dispute, error code: ${response.status}`, {
        timeout: 3000
    });
}

function storeStatement(event) {
    statement.value = event.target.files[0];
    dispute.value.statement = statement;
}

function storePOP(event) {
    pop.value = event.target.files[0];
    dispute.value.proof_of_payment = pop.value;
}

function isNumber(e) {
    //TODO see if this can be done with regex
    // Only ASCII character in that range allowed
    const ASCIICode = (e.which) ? e.which : e.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
        // allow full stop
        if (ASCIICode !== 46) {
            e.preventDefault();
        }
    }
    else {
        return true;
    }
}

</script>