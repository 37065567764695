import CreatePetition from './CreatePetition.vue'
import ListPetition from './ListPetition.vue'
import PublicDetailedPetition from './DetailedPetition.vue'

const adminRoutes = [
    //admin only
    { path: '/petitions/create', name: 'createPetition', component: CreatePetition, meta: { title: 'Create Petition', auth: true, admin: true } },
    { path: '/petitions/update/:slug', name: 'updatePetition', component: CreatePetition, meta: { title: 'Update Petition', auth: true, admin: true } },
]

const publicRoutes = [
    //no auth required
    { path: '/petitions', name: 'listPetition', component: ListPetition, meta: { title: 'All Petitions' } },
    { path: '/petitions/:slug', name: 'detailedPetition', component: PublicDetailedPetition, meta: { title: 'Petition Detail' } },
]

const petitionRoutes = adminRoutes.concat(publicRoutes)

export default petitionRoutes