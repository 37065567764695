import Campaign from './Campaign.vue'
import ListCampaign from './ListCampaign.vue'

const campaignRoutes = [
    { path: '/campaigns/new', name: 'newCampaign', component: Campaign, meta: { title: 'New Campaign', auth: true, admin: true } },
    { path: '/campaigns/:slug', name: 'updateCampaign', component: Campaign, meta: { title: 'Update Campaign', auth: true, admin: true } },

    { path: '/campaigns/', name: 'listCampaign', component: ListCampaign, meta: { title: 'Current Campaign' } },
]

export default campaignRoutes