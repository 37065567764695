import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';
import { isDate } from '@vue/shared';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'quill/dist/quill.snow.css';
import 'quill-image-resize-module/image-resize.min.js';
import 'quill-video-resize-module-fixed/video-resize.min.js';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
import VideoResize from 'quill-video-resize-module-fixed';
import CustomImage from './quillCustomImage';
import CustomVideo from './quillCustomVideo';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueExcelXlsx from "vue-excel-xlsx";
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/VideoResize', VideoResize)
Quill.register({'formats/image': CustomImage});
Quill.register({'formats/video': CustomVideo});

if (process.env.VUE_APP_DEV === 'true') {
  axios.defaults.baseURL = process.env.VUE_APP_HOST_PRIVATE;
}
else {
  axios.defaults.baseURL = process.env.VUE_APP_HOST_PUBLIC;
}

const filters = {
  price(input) {
    if (isNaN(input)) {
      return '-'
    }
    try {
      let p = parseFloat(input).toFixed(2)
      return 'R' + p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    catch {
      return input
    }
  },
  reference(input) {
    let num = input.toString()
    while (num.length < 8) num = "0" + num
    return num
  },
  dateTime(input) {
    if (isDate(input)) {
      return '-';
    }
    var date = new Date(input);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  },
  time(input) {
    if (isDate(input)) {
      return '-';
    }
    var date = new Date(input);
    return `${date.toLocaleTimeString()}`;
  },
  date(input) {
    if (isDate(input)) {
      return '-';
    }
    var date = new Date(input);
    return `${date.toLocaleDateString()}`;
  },
  dateInput(input) {
    var date = undefined;
    try {
      if (input == null) {
        return '-';
      } else {
        var date = new Date(input);
        if (date.getFullYear() == 1970) {
          return '-'
        }
        date.setHours(date.getHours() + 2);
        date = date.toISOString().replace('Z', '');
        return `${date}`;
      }
    } catch { }
    return '-';
  },
}

const app = createApp(App);
app.config.globalProperties.$filters = filters;

app.component('Datepicker', Datepicker);

app
  .use(store)
  .use(router, axios)
  .use(VueExcelXlsx)
  .use(Toast, {
    position: POSITION.BOTTOM_RIGHT
  })
  .mount('#app')