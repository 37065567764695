import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

//core templates
import HomeView from '../views/Core/HomeView.vue'
import About from '../views/Core/About.vue'
import Polices from '../views/Core/Policies.vue'
import Legislation from '../views/Core/Legislation.vue'
import LogIn from '../views/Core/LogIn.vue'
import Registration from '../views/Core/Registration.vue'
import AdminDashboard from '../views/Core/Admin/Dashboard.vue'

//app routes
import supportRoutes from '@/views/Core/Support/urls.js'
import disputeRoutes from '@/views/Disputes/urls.js'
import newsRoutes from '@/views/News/urls.js'
import complaintRoutes from '@/views/Complaint/urls.js'
import pollRoutes from '@/views/Poll/urls.js'
import accountRoutes from '@/views/Account/urls.js'
import donationRoutes from '@/views/Donation/urls.js'
import contactRoutes from '@/views/Contact/urls.js'
import petitionRoutes from '@/views/Petition/urls.js'
import campaignRoutes from '@/views/Campaign/urls.js'
import documentRoutes from '@/views/Document/urls.js'

import { useToast } from 'vue-toastification';

const toast = useToast();

const DEFAULT_TITLE = process.env.VUE_APP_SHORT_NAME || 'ERPA';

const baseRoutes = [
  { path: '/', name: 'home', component: HomeView, meta: { title: 'Home' } },
  { path: '/about', name: 'about', component: About, meta: { title: 'About' } },
  { path: '/policies', name: 'policies', component: Polices, meta: { title: 'policies' } },
  { path: '/registration', name: 'registration', component: Registration, meta: { title: 'Registration', auth: false } },
  { path: '/log-in', name: 'login', component: LogIn, meta: { title: 'Login' } },
  { path: '/legislation', name: 'legislation', component: Legislation, meta: { title: 'Legislation' } },
  { path: '/admin-dashboard', name: 'AdminDashboard', component: AdminDashboard, meta: { title: 'Admin Dashboard', auth: true, admin: true } },
]

const routes = baseRoutes
  .concat(accountRoutes)
  .concat(disputeRoutes)
  .concat(newsRoutes)
  .concat(complaintRoutes)
  .concat(pollRoutes)
  .concat(donationRoutes)
  .concat(supportRoutes)
  .concat(contactRoutes)
  .concat(petitionRoutes)
  .concat(campaignRoutes)
  .concat(documentRoutes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from) => {
  await store.commit('initializeStore');
  await store.dispatch('getProfile');

  if (to.matched.some((record) => record.meta.auth === true)) {
    if (store.state.isAuthenticated === false) {
      toast.info('Please log in to view this page.', {
        timeout: 3000
      });
      return { name: 'home' }
    }
  }
  if (to.matched.some((record) => record.meta.admin === true)) {
    if (store.state.account == undefined || store.state.account.is_staff === false) {
      toast.error('Unauthorized resource. Please contact administrator.', {
        timeout: 3000
      });
      return { name: 'home' }
    }
  }
  if (to.matched.some((record) => record.meta.auth === false)) {
    if (store.state.isAuthenticated === true) {
      toast.info('You are already signed into your account.', {
        timeout: 3000
      });
      return { name: 'home' }
    }
  }
  document.title = `${to.meta.title} | ${DEFAULT_TITLE}`;
  return;
})

export default router