import LandingPage from './LandingPage.vue'
import EmergencyContacts from './EmergencyContacts.vue'
import CommunityChat from './CommunityChat.vue'
import TelegramGroups from './TelegramGroups.vue'


const complaintRoutes = [
    { path: '/support', name: 'LandingPage', component: LandingPage, meta: { title: 'Support' } },
    { path: '/support/emergency', name: 'EmergencyContacts', component: EmergencyContacts, meta: { title: 'Emergency' } },
    { path: '/support/community-chat', name: 'CommunityChat', component: CommunityChat, meta: { title: 'Community Chat' } },
    { path: '/support/telegram-groups', name: 'TelegramGroups', component: TelegramGroups, meta: { title: 'Telegram Groups' } },
]

export default complaintRoutes