<template>
  <div class="centered-content">
    <h3>Donations</h3>
  </div>
  <hr>
  <template v-if="donations && donations.length > 0">
    <vue-excel-xlsx 
      v-if="donations.length > 0"
      class="btn btn-primary w-100 mb-3"
      :data="donations"
      :columns="columns"
      :file-name="'Donations'"
      :file-type="'xlsx'"
      :sheet-name="'All Donations'"
    >
      Export
    </vue-excel-xlsx>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Date</th>
          <th>Full Name</th>
          <th>Email</th>
          <th>Primary Contact</th>
          <th>Category</th>
          <th>Description</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="donation in donations">
          <tr>
            <td>{{ $filters.date(donation.date_created) }}</td>
            <td>{{ donation.full_name }}</td>
            <td>{{ donation.email }}</td>
            <td>{{ donation.primary_contact }}</td>
            <td>{{ donation.category.description }}</td>
            <td>{{ donation.description }}</td>
            <td>{{ donation.amount }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </template>
  <template v-else>
    No Donations
  </template>
</template>

<style scoped>

</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'

const store = useStore()
const router = useRouter()
const route = useRoute()
const toast = useToast()

let donations = ref(undefined)

let columns = ref([
  {label: 'id', field: "id"},
  {label: 'date', field: "date_created"},
  {label: 'full_name', field: "full_name"},
  {label: 'email', field: "email"},
  {label: 'primary_contact', field: "primary_contact"},
  {label: 'category', field: "category.description"},
  {label: 'description', field: "description"},
  {label: 'amount', field: "amount"},
])

onMounted(async () => {
  await getDonations()
})

async function getDonations() {
  const response = await store.dispatch('get', 'donations/dashboard')
  if (response.status === 200) {
    donations.value = response.data
  }
}
</script>