<template>
    <h3>Thank you {{ donation.full_name }} for your donation of {{ $filters.price(donation.amount) }} towards {{ donation.category }}!</h3>
    <br>
    <h3>This donation will be used to improve {{ donation.category }} in your area.</h3>

    <p>This will go a long way in helping to build a better Emfuleni.</p>

    <h3>Please use the following information when completing the EFT payment:</h3>
    <table class="table">
      <tbody>
        <tr>
          <td>Bank</td>
          <td>FIRST NATIONAL BANK (FNB)</td>
        </tr>
        <tr>
          <td>Account Name</td>
          <td>EMFULENI RATEPAYERS ASSOCIATION NPC</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>BUSINESS ACCOUNT (CURRENT)</td>
        </tr>
        <tr>
          <td>Account Number</td>
          <td>62894802464</td>
        </tr>
        <tr>
          <td>Branch Code</td>
          <td>255355</td>
        </tr>
        <tr>
          <td>SWIFT Code</td>
          <td>FIRNZAJJ</td>
        </tr>
        <tr>
          <td>Amount</td>
          <td>{{ $filters.price(donation.amount) }}</td>
        </tr>
        <tr>
          <td>Reference</td>
          <td>{{ donation.date.slice(2,10) }}-{{ donation.category }}-{{ donation.id }}</td>
        </tr>
        <tr>
          <td>Bank Confirmation</td>
          <td>            
              <a :href="donation.pdf" download="Bank Confirmation.pdf">Download Bank Confirmation</a>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
        If you would like to make another donation, please click
        <router-link :to="{ name: 'NewDonation' }">here</router-link>
    </p>

    <p>
        If this reference is not used when making payment, ERPA will not be able to allocate the payment
        and these monies will be re-applied after 3 months if ERPA cannot trace the amount to the owner.
    </p>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'

const toast = useToast();
const route = useRoute();

let donation = ref({
  id: route.query.id || undefined,
  date: route.query.date || undefined,
  full_name: route.query.full_name || undefined,
  email: route.query.email || undefined,
  primary_contact: route.query.primary_contact || undefined,
  category: route.query.category || undefined,
  description: route.query.description || undefined,
  amount: route.query.amount || undefined,
  pdf: route.query.pdf || undefined,
})

onMounted(async () => {
    toast.success('Thank you so much!', {
        timeout: 3000
    });
})
</script>