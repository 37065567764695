<template>
  <div id="wrapper" class="app">
    <Nav />
    <div style="padding-bottom: 100px;" class="container mt-5">
      <div class="centered-content">
        <div class="card card-body w-100">
          <router-view />
        </div>
      </div>
    </div>
    <div v-if="store.state.isLoading">
      <Spinner />
    </div>
    <Footer />
    <img style="
        position: fixed;
        top: 0; 
        height: 100%;
        width: 100%;
        z-index: -999;
      "
     src="../public/img/icons/Background.jpg">
  </div>
</template>

<script setup>
import Nav from './components/nav.vue'
import Footer from './components/footer.vue'
import Spinner from './components/Spinner'
import { useStore } from 'vuex'

const store = useStore();

</script>

<style>

.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.centered-content {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  align-content: center !important;
  justify-items: center !important;
  justify-content: center !important;
  vertical-align: middle !important;
}

.left-content {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
  justify-items: left !important;
  justify-content: left !important;
  vertical-align: middle !important;
}

.right-content {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
  justify-items: right !important;
  justify-content: right !important;
  vertical-align: middle !important;
}

.material-icons {
  cursor: pointer;
}
</style>
