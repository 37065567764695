<template>
    <div class="centered-content">
        <h3>Administrative Functions</h3>
    </div>
    <hr>

    <div>
      <ul>
        <li><router-link :to="{name: 'ListCriteria'}">Dispute Criteria</router-link></li>
        <li><router-link :to="{name: 'DonationDashboard'}">Donation Dashboard</router-link></li>
      </ul>
    </div>
</template>
