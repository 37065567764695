<template>
    <h3 style="text-align: center;">Support</h3>
    <hr>
    <div class='row'>
        <router-link class='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 removeStyle' :to="{name: 'EmergencyContacts'}" st >
            <div class="card card-body centered-content" >
                <img src="./1.gif">
                <h3>Emergency Contacts</h3>
                <p>This page contains all the emergency contact numbers that you will ever need.</p>
            </div>
        </router-link>
        <router-link class='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 removeStyle' :to="{name: 'CommunityChat'}" st >
            <div class="card card-body centered-content" >
                <img src="./1.gif">
                <h3>Community Chat</h3>
                <p>Help us to help you by posting your ELM-related issues here. Subscription is a breeze.</p>
            </div>
        </router-link>
        <router-link class='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 removeStyle' :to="{name: 'TelegramGroups'}" st >
            <div class="card card-body centered-content" >
                <img src="./1.gif">
                <h3>Telegram Groups</h3>
                <p>Check out the other Telegram Groups here (for ERPA Members only)</p>
            </div>
        </router-link>
    </div>
</template>

<style scoped>
    .removeStyle {
        text-decoration: none !important;
        color: black;
    }
    p {
        text-align: center;
    }
</style>
