<template>
  <div class="centered-content">
    <h3 style="text-align: center;">Petitions
      <router-link v-if="store.state.account && store.state.account.is_staff" class="ms-auto"
        :to="{ name: 'createPetition' }">
        <li class="material-icons text-success" style="cursor: pointer;">post_add</li>
      </router-link>
    </h3>
  </div>
  <hr>
  <template v-if="petitions.length > 0" v-for="obj, idx in petitions">
    <router-link class='card shadow mb-3' style="text-decoration: none; color: inherit;"
      :to="{ name: 'detailedPetition', params: { slug: obj.slug } }">
      <div class='card-header'>
        <h3>{{ obj.title }}</h3>
        <h6>{{ $filters.dateTime(obj.date_modified) }}</h6>
        <h6 v-if="obj.active">In Progress</h6>
        <h6 v-else>Ended</h6>
      </div>
      <div :id="'editor-' + idx" class='card-body' style="height:300px; overflow:auto;"></div>
    </router-link>
    <hr v-if="idx + 1 < petitions.length">
  </template>
  <template v-else>
    <p>No Active petitions. Stay tuned for more</p>
  </template>
</template>

<style scoped>
.img {
  max-width: 100%;
  max-height: 100px;
  z-index: 0;
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore();

let petitions = ref([]);

onMounted(async () => {
  await getPetition();
  createQuills();
})

function createQuill(el, contents) {
  let quill = new Quill(el, {
    modules: { toolbar: false, },
    readOnly: true,
  });
  quill.setContents(contents);
}

function createQuills() {
  let data = petitions.value;
  let count = -1;
  data.forEach(async (obj) => {
    count = count + 1;
    let body = JSON.parse(obj.body);
    createQuill(`#editor-${count}`, body);
  });
}

async function getPetition() {
  const response = await store.dispatch(
    'get',
    store.state.account && store.state.account.is_staff ? 'petitions/' : 'petitions/public/'
  );
  if (response.status == 200) {
    petitions.value = response.data;
  }
}
</script>