<template>
  <input
    ref="inputRef"
    type="text"
    class="form-control"
  />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: Number, // Vue 2: value
  },
  setup(props) {
    const { inputRef } = useCurrencyInput({
        "locale": "en-IN",
        "currency": "EUR",
        "currencyDisplay": "hidden",
        "valueRange": {
            "min": 0
        },
        "precision": 2,
        "hideCurrencySymbolOnFocus": true,
        "hideGroupingSeparatorOnFocus": true,
        "hideNegligibleDecimalDigitsOnFocus": true,
        "autoDecimalDigits": false,
        "useGrouping": true,
        "accountingSign": true
    })

    return { inputRef }
  }
}
</script>