import LodgeDispute from './LodgeDispute.vue'
import DetailedDispute from './DetailedDispute.vue'
import ListDisputes from './ListDisputes.vue'

import ListCriteria from './Criteria/CriteriaList.vue'
import CreateCriteria from './Criteria/CreateCriteria.vue'

const disputeRoutes = [
    {path:'/disputes/lodge', name: 'lodgeDispute', component: LodgeDispute, meta: { title: 'Lodge Dispute', auth: true }},
    {path:'/disputes/detail/:id', name: 'detailDispute', component: DetailedDispute, meta: { title: 'Dispute Detail', auth: true }},
    {path:'/disputes', name: 'listDisputes', component: ListDisputes, meta: { title: 'All Disputes', auth: true }},

    {path:'/disputes/criteria', name: 'ListCriteria', component: ListCriteria, meta: { title: 'All Criteria', auth: true, admin: true }},
    {path:'/disputes/criteria/create', name: 'CreateCriteria', component: CreateCriteria, meta: { title: 'Create Criteria', auth: true, admin: true }},
    {path:'/disputes/criteria/update/:id', name: 'UpdateCriteria', component: CreateCriteria, meta: { title: 'Update Criteria', auth: true, admin: true }},
]

export default disputeRoutes