<template>
    <h3 style="text-align: center;">Contact Us</h3>
    <hr>

    <div id="form" class='row'>
        <div class='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4 centered-content mb-2'>
            <img class="w-100" src="./ERPA_631.png">
        </div>
        <div class='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-8 col-xxl-8'>
            <div class='card'>
                <form @submit.prevent="showModal('Submit', 'Are you sure your details are complete?', 'Yes', 'No', () => submit())">
                    <div class='card-body'>
                        <div id="full_name" class="mb-3">
                            <label for="full_name" class="form-label">Full Name</label>
                            <input id="full_name" type="text" class="form-control" v-model="contact.full_name"
                                required />
                            <label v-if="errors && errors.full_name" v-for="error in errors.full_name" for="email"
                                class="form-label text-danger">
                                {{ error }}
                            </label>
                        </div>
                        <div id="email" class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input id="email" type="text" class="form-control" v-model="contact.email" required />
                            <label v-if="errors && errors.email" v-for="error in errors.email" for="email"
                                class="form-label text-danger">
                                {{ error }}
                            </label>
                        </div>
                        <div id="description" class="mb-3">
                            <label for="description" class="form-label">Message</label>
                            <textarea id="description" type="text" class="form-control" v-model="contact.description"
                                rows="8" required />
                            <label v-if="errors && errors.description" v-for="error in errors.description"
                                for="description" class="form-label text-danger">
                                {{ error }}
                            </label>
                        </div>
                        <div id="mail" class="mb-3 form-check">
                            <input id="mailMe" type="checkbox" class="form-check-input" v-model="mailMe" />
                            <label for="mailMe" class="form-check-label">Send Copy to your inbox</label>
                            <label v-if="errors && errors.mail" v-for="error in errors.mail" for="editor"
                                class="form-label text-danger">{{ error }}</label>
                        </div>
                    </div>
                    <div class='card-footer'>
                        <button class="btn btn-primary w-100">Submit</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
    <hr>

    <div id="contacts" class="centered-content">

        <div class='row'>
            <div class='col-auto'>
                <div class='card'>
                    <div class='card-header'>
                        <h3>Administrative Contacts</h3>
                    </div>
                    <div class='card-body'>
                        <ul>
                            <li>Chair - <a href="mailto:kobus@erpa.co.za">Kobus Janse van Rensburg</a></li>
                            <li>Treasurer - <a href="mailto:stafan@erpa.co.za">Stefan Olivier</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class='col-auto'>
                <div class='card'>
                    <div class='card-header'>
                        <h3>Liaison Contacts</h3>
                    </div>
                    <div class='card-body'>
                        <ul>
                            <li>News Media - <a href="mailto:liezl@erpa.co.za">Liezl Klemm Viljoen</a></li>
                            <li>Community - <a href="mailto:anke@erpa.co.za">Anke van der Schyff</a></li>
                            <li>Health & Safety - <a href="mailto:chris@erpa.co.za">Chris Viljoen</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class='col-auto'>
                <div class='card'>
                    <div class='card-header'>
                        <h3>Support Contacts</h3>
                    </div>
                    <div class='card-body'>
                        <ul>
                            <li>Information - <a href="mailto:info@erpa.co.za">info@erpa.co.za</a></li>
                            <li>Support - <a href="mailto:support@erpa.co.za">support@erpa.co.za</a></li>
                            <li>Administration - <a href="mailto:admin@erpa.co.za">admin@erpa.co.za</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>

    <div id="stonehaven" class='card h-100'>
        <div class='card-header'>
            <h3>Stonehaven on Vaal</h3>
        </div>
        <div class='card-body'>
            <div class='row'>
                <div class='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6'>
                    <p>
                        The Management of the fantastic Restaurant, Family and Business Venue on the banks of the Vaal
                        River,
                        <a href="https://stonehaven.co.za/" target="_blank">Stonehaven on Vaal</a>
                        , has graciously made their premises available for ERPA meetings, until we have a permanent
                        office.
                    </p>
                    <p>Please support them liberally.</p>
                </div>
                <div class='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6'>
                    <img class="w-100" style="border-radius: 25px;" src="./Stonehaven-Vaal-cruises.jpg">
                </div>
            </div>
        </div>
    </div>
    <hr>

    <div v-if="store.state.isAuthenticated == false">
        <RegisterNow />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { showModal } from '../../components/customModal.js'
import RegisterNow from './../Core/RegisterNow'

const store = useStore();
const toast = useToast();

let contact = ref({
    full_name: undefined,
    email: undefined,
    primary_contact: undefined,
    description: undefined,
});
let mailMe = ref(true);
let errors = ref(undefined);

onMounted(async () => {
    if (store.state.account) {
        let account = store.state.account;
        let temp = contact.value;

        let first_name = account.first_name ? account.first_name : '';
        first_name += account.first_name && account.last_name ? ' ' : '';
        first_name += account.last_name ? account.last_name : '';
        temp.full_name = first_name;

        temp.email = account.email ? account.email : '';
        temp.primary_contact = account.primary_contact;
        contact.value = temp;
    }
})

async function submit() {
    const data = {
        api: 'contact/',
        data: contact.value,
    }

    const response = await store.dispatch('post', data);

    if (response.status == 201) {
        toast.success('Your message was submitted successfully!', {
            timeout: 3000
        });

        const contactUs = response.data;

        if (mailMe.value == true) {
            await sendMail(contactUs);
        }
    }
    else {
        errors.value = response.data;
    }
}

async function sendMail(contact) {
    const data = {
        api: 'contact/email/',
        data: contact,
    }

    const response = await store.dispatch('post', data);

    if (response.status == 200) {
        toast.success(`An Email was sent to your inbox ${contact.email}!`, {
            timeout: 3000
        });
    }
    else {
        toast.error(`An Error occurred sending email to your inbox ${contact.email}!`, {
            timeout: 3000
        });
    }
}
</script>