<template>
  <nav class="navbar navbar-expand-xl navbar-dark sticky-top d-print-none" style="background-color: rgb(46, 163, 242);">
    <div class="container-fluid">

      <router-link :to="{ name: 'home' }" class="navbar-brand">
        <img style="height: 50px !important;" src="./Small-Logo.png">
      </router-link>

      <div class="collapse navbar-collapse" id="navbarToggler">
        <!-- right hand -->
        <NavLinks :links="routes?.leftRoutes" />
        
        <!-- right hand -->
        <div class="d-flex ms-auto">
          <ul class="navbar-nav">
            <template v-if="store.state.isAuthenticated === true">

              <NavLinks :links="routes?.rightRoutes" />
              
              <li class="nav-item">
                <!-- action item -->
                <a class="nav-link active" @click="showModal('Logout', 'Are you sure you want to log out?', 'Yes', 'No', () => logout())">
                  Log off
                </a>
              </li>
            </template>
            <template v-else-if="store.state.isAuthenticated == false">
              <NavLinks :links="routes?.noAuthRoutes" />
            </template>
          </ul>
        </div>
      </div>

      <button id="navbarBtn" class="navbar-toggler m-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler"
        aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>
</template>
  
<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { showModal } from '../components/customModal.js'
import NavLinks from './NavLinks.vue';
import { delay }  from '@/components/utils'

const router = useRouter();
const store = useStore();
let routes = ref({
  noAuthRoutes: [],
  leftRoutes: [],
  rightRoutes: [],
})

function logout() {
  const payload = {
    api: '/auth/token/logout/',
    data: {}
  }
  store.dispatch('post', payload);
  store.commit('removeTokens');
  router.push({ name: 'home' });
  toggleNav();
}

function toggleNav() {
  try {
    var nav = document.getElementById("navbarToggler");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  } catch {}
};

onMounted(async () => {
  routes.value.noAuthRoutes = [
    { type: 'single', label: 'Register', to: { name: 'registration' } },
    { type: 'single', label: 'Log In', to: { name: 'login' } },
  ]

  routes.value.leftRoutes = [
    { type: 'single', label: 'Home', to: { name: 'home' } },
    { type: 'single', label: 'Polls', to: { name: 'listPolls' } },
    { type: 'single', label: 'Petitions', to: { name: 'listPetition' } },
    { type: 'single', label: 'Disputes', to: { name: 'lodgeDispute' } },
    { type: 'single', label: 'Complaints', to: { name: 'lodgeComplaint' } },
    { type: 'single', label: 'News', to: { name: 'listNews' } },
    { type: 'single', label: 'Campaigns', to: { name: 'listCampaign' } },
    { type: 'single', label: 'Documents', to: { name: 'listDocument' } },
    { type: 'single', label: 'Donate', to: { name: 'NewDonation' } },
    { type: 'single', label: 'Dashboard', to: { name: 'AdminDashboard' }, admin: true },
  ]

  routes.value.rightRoutes = [
    { type: 'single', label: `Hi!`, account: true },
    { type: 'dropdown', label: 'My Account', to: [
        { label: 'Lodged Complaints', to: { name: 'listComplaints' } },
        { label: 'Lodged Disputes', to: { name: 'listDisputes' } },
        { label: 'My Profile', to: { name: 'profile' } },
      ]
    },
  ]
})

</script>
  
<style type="text/css">
a:hover {
  cursor: pointer;
}

@media all {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .nav-link {
    color: #fff;
  }

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}

#navbarBtn {
  position: fixed;
  top: 0;
  right: 0;
}
</style>
  