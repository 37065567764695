<template>
    <div>
        <h3 style="text-align: center;">News
            <router-link v-if="store.state.account && store.state.account.is_staff" class="ms-auto"
                :to="{ name: 'createNews' }">
                <span class="material-icons text-success" style="cursor: pointer;">post_add</span>
            </router-link>
        </h3>
    </div>

    <hr>

    <div class="left-content">
        <div id="filters" class="card card-body mb-2">
            <h5 class="fw-bold w-auto" for="title">Search news articles by Title / Description</h5>
            <input id="title" class="form-control" type="text" v-model="filter" placeholder="search">
        </div>
    </div>

    <nav v-if="pageCount > 1" aria-label="Page navigation example">
        <ul class="pagination">
            <li v-if="pageCount > 1" class="page-item page-link" @click="decPage()">Previous</li>
            <template v-for="index in pageCount">
                <li class="page-item page-link" @click="setPage(index)">{{ index }}</li>
            </template>
            <li v-if="pageCount > 1" class="page-item page-link" @click="incPage()">Next</li>
        </ul>
    </nav>

    <template v-if="news.length > 0" v-for="article, idx in news">
        <router-link v-if="article.page == page && (
            article.title.toLowerCase().includes(filter.toLowerCase()) ||
            article.body.toLowerCase().includes(filter.toLowerCase())
        )" :to="{ name: 'detailedNews', params: { slug: article.slug } }" class='card mb-2 shadow'
            style="text-decoration: none; color: black;">
            <div class='card-header'>
                <div class='row'>
                    <div class='col-8'>
                        <h3>{{ article.title }}</h3>
                        <h6>{{ article.account }}</h6>
                        <h6>{{ $filters.dateTime(article.date_modified) }}</h6>
                    </div>
                    <div class='col-4 right-content'>
                        <template v-if="article.profile_image">
                            <img :src="article.profile_image" class="img">
                        </template>
                    </div>
                </div>
            </div>
            <div class='card-body' style="height:150px; overflow: auto;">
                <div :id="'editor-' + idx"></div>
            </div>
        </router-link>
        <hr>
    </template>

    <nav v-if="pageCount > 1" aria-label="Page navigation example">
        <ul class="pagination">
            <li v-if="pageCount > 1" class="page-item page-link" @click="decPage()">Previous</li>
            <template v-for="index in pageCount">
                <li class="page-item page-link" @click="setPage(index)">{{ index }}</li>
            </template>
            <li v-if="pageCount > 1" class="page-item page-link" @click="incPage()">Next</li>
        </ul>
    </nav>

</template>

<style scoped>
img {
    max-width: 100px;
    max-height: 100px;
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore();

let news = ref([]);
let page = ref(1);
let pageCount = ref(undefined);
let filter = ref('');

onMounted(async () => {
    await getNews();
    createQuills();
})

async function getNews() {
    const response = await store.dispatch(
        'get',
        store.state.account && store.state.account.is_staff ? 'news/' : 'news/public/'
    );

    if (response.status == 200) {
        let newNews = response.data;
        let length = Math.ceil((news.value.length) / 10); //TODO this value isn't being used?
        let count = 0;
        let getPageCount = 1;
        newNews.forEach((article) => {
            count++;
            if (count == 10) {
                count = 0;
                getPageCount++;
            }
            article.page = getPageCount;
        })
        pageCount.value = getPageCount;
        news.value = newNews;
    }
}

function createQuill(el, contents) {
    let quill = new Quill(el, {
        modules: {
            toolbar: false,
        },
        readOnly: true,
    });
    quill.setContents(contents);
}

function createQuills() {
    let data = news.value;
    let count = -1;
    data.forEach((obj) => {
        count = count + 1;
        let body = JSON.parse(obj.body);
        createQuill(`#editor-${count}`, body);
    });
}

function setPage(newPage) {
    page.value = newPage;
}

function incPage() {
    let newPage = page.value + 1;
    if (newPage > pageCount.value) { return; }
    page.value = newPage;
}

function decPage() {
    let newPage = page.value - 1;
    if (newPage < 1) { return; }
    page.value = newPage;
}

</script>