<template>
    <div class='card'>
        <div class='card-header'>
            <h3 class="fw-bold">
                NOTE: Only validated Emfuleni ratepayers/residents will be allowed to use this portal.
            </h3>
        </div>
        <div class='card-body'>
            <p>
                Additional information specific to ratepayers/residents will be required,
                without which you will not be able to update your profile. 
                This step is necessary to avoid abuse of features on 
                this website by non-Emfuleni users.
            </p>
            <p>
                Thank you for your understanding.
            </p>
            <p>
                Helping you, help us, help the Emfuleni Community.
            </p>
        </div>
    </div>
</template>

<script setup>
</script>