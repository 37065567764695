<template>
    <div class="centered-content">
        <h3>Manage Dispute Criteria</h3>
        <router-link class="material-icons text-success" role="button" style="text-decoration: none;"
            :to="{name: 'CreateCriteria'}">
            post_add
        </router-link>
    </div>
    <hr>
    
    <div v-if="criteria.length > 0" class="centered-content">
        <table class="table table-hover w-auto">
            <thead>
                <tr>
                    <th>Dispute Type</th>
                    <th>Header</th>
                    <th>Bank Confirmation</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="crit in criteria">
                    <tr>
                        <td>{{ crit.dispute_type }}</td>
                        <td>{{ crit.header }}</td>
                        <td>
                          <a v-if="crit.bank_confirmation" :href="crit.bank_confirmation" :download="crit.dispute_type + ' ' + crit.header + 'pop.pdf'">
                            Download POP
                          </a>
                        </td>
                        <td>
                            <router-link class="material-icons text-success" :to="{name: 'UpdateCriteria', params: {id:crit.id}}" style="text-decoration: none;">create</router-link>
                            <li class="material-icons text-danger" @click="deleteCriteria(crit)">delete</li>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
    <div v-else>
        No Criteria Added
    </div>
</template>

<style scoped>

</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'

const store = useStore()
const router = useRouter()
const route = useRoute()
const toast = useToast()

let criteria = ref([])

onMounted(async () => {
    await getCriteria()
})

async function getCriteria() {
    const response = await store.dispatch('get', 'disputes/criteria/')
    if (response.status === 200 ) {
        criteria.value = response.data
    }
}

async function deleteCriteria(crit) {
    if (!confirm('Are you sure you want to delete this criteria')) {
        return
    }
    const response = await store.dispatch('delete', `disputes/criteria/${crit.id}/`)
    if (response.status === 202) {
        toast.success(`Criteria Deleted`, {
            timeout: 5000
        });
        await getCriteria()
    }
    else {
        toast.error(response.data, {
            timeout: 5000
        });
    }
}
</script>