import { createStore } from 'vuex'
import axios from 'axios'
import router from '../router/index.js'
import { APIResponse } from '../models/apiResponse.js'

//TODO: Extract error handling method in GET,POST and PUT calls
export default createStore({
  state: {
    //on startup / refresh
    isLoading: false,
    auth_token: localStorage.getItem('auth_token') || null,
    isAuthenticated: localStorage.getItem('auth_token') ? true : false,
    shortName: process.env.VUE_APP_SHORT_NAME,
    fullName: process.env.VUE_APP_FULL_NAME,
    account: undefined,
    profileCompleted: false,
    host: process.env.VUE_APP_DEV === 'true' ? process.env.VUE_APP_HOST_PRIVATE : process.env.VUE_APP_HOST_PUBLIC,
  },
  mutations: {
    setIsLoading(state, status) {
      state.isLoading = status
    },

    async initializeStore(state) {
      if (state.isAuthenticated) {
        this.commit('setTokens', state.auth_token)
      } else {
        this.commit('removeTokens')
      }
    },

    setTokens(state, auth_token) {
      console.log('setting tokens');
      if (auth_token) {
        state.auth_token = auth_token
        localStorage.setItem('auth_token', auth_token)
        state.isAuthenticated = true
        axios.defaults.headers.common['Authorization'] = 'Token ' + state.auth_token
      }
      else {
        this.commit('removeTokens')
      }
    },

    removeTokens(state) {
      delete axios.defaults.headers.common['Authorization']
      state.account = undefined
      state.auth_token = undefined
      state.isAuthenticated = false
      localStorage.clear()
    },
  },
  actions: {
    async get({ commit, state }, api) {
      console.log(`GET ${api}`);
      commit('setIsLoading', true)
      const response = await axios
        .get(api)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          // This error handling is recommended by axios: https://axios-http.com/docs/handling_errors
          if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx

            if (error.response.status === 401) {
              router.push({ name: 'home' })
            }

            return error.response;
          }

          if (error.request) {
            // The request was made but no response was received.
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return new APIResponse(null, 500, 'Backend Service Unavailable');
          }

          // Something happened in setting up the request that triggered an Error
          return new APIResponse(null, 0, error.message)
        })

      commit('setIsLoading', false);
      return response;
    },
    async download({ commit, state }, api) {
      console.log(`Download ${api}`);
      commit('setIsLoading', true)
      const response = await axios
        .get(api, { responseType: 'blob' })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              router.push({ name: 'home' });
            }
            return error.response;
          }
          if (error.request) {
            return new APIResponse(null, 500, 'Backend Service Unavailable');
          }
          return new APIResponse(null, 0, error.message);
        })

      commit('setIsLoading', false);
      console.log(response);
      return response;
    },
    async post({ commit, state }, payload) {
      console.log(`POST ${payload.api}`);
      commit('setIsLoading', true)

      const response = await axios
        .post(payload.api, payload.data, payload.headers ? payload.headers : { 'Content-Type': 'application/json' })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          // This error handling is recommended by axios: https://axios-http.com/docs/handling_errors
          if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx

            if (error.response.status === 401) {
              commit('removeTokens')
              router.push({ name: 'home' })
            }

            return error.response;
          }

          if (error.request) {
            // The request was made but no response was received.
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return new APIResponse(null, 500, 'Backend Service Unavailable');
          }

          // Something happened in setting up the request that triggered an Error
          return new APIResponse(null, 0, error.message);
        })

      commit('setIsLoading', false);
      return response;
    },
    async put({ commit, state }, payload) {
      console.log(`PUT ${payload.api}`);
      commit('setIsLoading', true)

      const response = await axios
        .put(payload.api, payload.data, payload.headers ? payload.headers : { 'Content-Type': 'application/json' })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          // This error handling is recommended by axios: https://axios-http.com/docs/handling_errors
          if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx

            if (error.response.status === 401) {
              commit('removeTokens')
              router.push({ name: 'home' })
            }

            return error.response;
          }

          if (error.request) {
            // The request was made but no response was received.
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return new APIResponse(null, 500, 'Backend Service Unavailable');
          }

          // Something happened in setting up the request that triggered an Error
          return new APIResponse(null, 0, error.message);
        })

      commit('setIsLoading', false);
      return response;
    },
    async delete({ commit, state }, api) {
      console.log(`Delete ${api}`);
      commit('setIsLoading', true)

      const response = await axios
        .delete(api)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          // This error handling is recommended by axios: https://axios-http.com/docs/handling_errors
          if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx

            if (error.response.status === 401) {
              commit('removeTokens')
              router.push({ name: 'home' })
            }

            return error.response;
          }

          if (error.request) {
            // The request was made but no response was received.
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return new APIResponse(null, 500, 'Backend Service Unavailable');
          }

          // Something happened in setting up the request that triggered an Error
          return new APIResponse(null, 0, error.message);
        })

      commit('setIsLoading', false);
      return response;
    },
    async getProfile({ state, dispatch, commit }) {
      if (state.isAuthenticated) {

        const response = await dispatch('get', 'account/profile/');

        if (response.status === 200) {
          state.account = response.data;

          state.profileCompleted = state.account.email
            && state.account.first_name
            && state.account.ownership
            && state.account.primary_contact
            && state.account.identification_type
            && state.account.identification_number
            && state.account.electrical_meter_type
            && state.account.electricity_meter_number
            && state.account.municipal_account_number
            && state.account.street_number
            && state.account.street_name
            && state.account.suburb
            && state.account.town ? true : false;

            return
        }
        else {
          commit('removeTokens');
        }
      }
    },
  },
  getters: {},
  modules: {},
})
