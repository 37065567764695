<template>
  <div class="row centered-content">
    <div v-if="account" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-6">
      <h3 style="text-align: center;">{{ account.first_name }}'s Profile</h3>
      <hr>
      <div class="nav nav-tabs" role="tablist">
        <button class="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
          role="tab" aria-controls="profile" aria-selected="false">Profile</button>
        <button class="nav-link" id="password-tab" data-bs-toggle="tab" data-bs-target="#password" type="button"
          role="tab" aria-controls="password" aria-selected="false">Change Password</button>
        <button class="nav-link" id="municipal-tab" data-bs-toggle="tab" data-bs-target="#municipal_account_update" type="button"
          role="tab" aria-controls="municipal_account_update" aria-selected="false">ELM Account</button>
      </div>
      <div class="tab-content">
        <div class="tab-pane active" id="profile" aria-labelledby="profile-tab" role="tabpanel">
          <div class='card shadow-lg'>
            <div class='card-header centered-content'>
              <h5>Update your profile in order to lodge complaints and disputes</h5>
            </div>
            <div class='card-body w-auto' style="display: flex;">
              <form @submit.prevent="updateProfile" class="w-100">
                <table class="table w-100">
                  <tbody>
                    <tr>
                      <td>
                        <label class="required" for="email">Email</label>
                        <div><small>Read-only field</small></div>
                      </td>
                      <td>
                        <input id="email" class="form-control" v-model="account.email" readonly placeholder="Email">
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label class="required" for="first_name">First Name</label>
                        <div><small>Read-only field</small></div>
                      </td>
                      <td>
                        <input id="first_name" class="form-control" v-model="account.first_name" readonly placeholder="First Name">
                      </td>
                    </tr>
                    <tr>
                      <td><label for="last_name">Last Name</label></td>
                      <td>
                        <input id="last_name" class="form-control" v-model="account.last_name" placeholder="Last Name">
                        <ul v-if="(errors && errors.last_name)">
                          <li for="last_name" class="form-label text-danger col-12" v-for="error in errors.last_name">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><label class="required" for="primary_contact">Ownership Status</label></td>
                      <td>
                        <select class="form-select" v-model="account.ownership">
                          <option class="form-control" disabled>Ownership Status</option>
                          <option class="form-control" value="Personal">Personal</option>
                          <option class="form-control" value="Company">Company</option>
                          <option class="form-control" value="Tenant">Tenant</option>
                        </select>
                        <ul v-if="(errors && errors.ownership)">
                          <li for="ownership" class="form-label text-danger col-12" v-for="error in errors.ownership">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><label class="required" for="primary_contact">Primary Contact</label></td>
                      <td>
                        <input id="primary_contact" class="form-control" type="tel" v-model="account.primary_contact"
                          v-maska="primary_bind" data-maska="(###) ###-####" placeholder="eg. (012) 345-6789">
                        <ul v-if="(errors && errors.primary_contact)">
                          <li for="primary_contact" class="form-label text-danger col-12"
                            v-for="error in errors.primary_contact">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><label for="alternative_contact">Alternative Contact</label></td>
                      <td>
                        <input id="alternative_contact" class="form-control" type="tel"
                          v-model="account.alternative_contact" v-maska="alternative_bind" data-maska="(###) ###-####"
                          placeholder="eg. (012) 345-6789">
                        <ul v-if="(errors && errors.alternative_contact)">
                          <li for="alternative_contact" class="form-label text-danger col-12"
                            v-for="error in errors.alternative_contact">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><label class="required" for="identification_type">Identification Type</label></td>
                      <td>
                        <select @change="onIdTypeChange" id="identification_type" class="form-select"
                          v-model="account.identification_type">
                          <option class="form-select" value="South African ID">South African ID</option>
                          <option class="form-select" value="Passport">Passport</option>
                        </select>
                        <ul v-if="(errors && errors.identification_type)">
                          <li for="identification_type" class="form-label text-danger col-12"
                            v-for="error in errors.identification_type">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr v-if="account.identification_type == 'Passport'">
                      <td><label class="required" for="identification_number">Passport Number</label>
                      </td>
                      <td>
                        <input id="identification_number" class="form-control" type="text"
                          v-model="account.identification_number" maxlength="9" placeholder="Passport Number"
                          @keypress="handleInput($event)">
                        <ul v-if="(errors && errors.identification_number)">
                          <li for="identification_number" class="form-label text-danger col-12"
                            v-for="error in errors.identification_number">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr v-else>
                      <td><label class="required" for="identification_number">Identification Number</label>
                      </td>
                      <td>
                        <input id="identification_number" class="form-control" type="text"
                          v-model="account.identification_number" v-maska data-maska="###### #### ###" maxlength="15"
                          placeholder="Identification Number" @keypress="handleInput($event)">
                        <ul v-if="(errors && errors.identification_number)">
                          <li for="identification_number" class="form-label text-danger col-12"
                            v-for="error in errors.identification_number">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <label class="required" for="municipal_account_number">Municipal Account Number (ELM)</label>
                        <div><small>Required to lodge a <b>Billing</b> dispute</small></div>
                      </td>
                      <td><input id="municipal_account_number" class="form-control" type="text"
                          v-model="account.municipal_account_number" v-maska data-maska="#### ####" maxlength="9"
                          placeholder="Municipal Account Number" @keypress="isNumber($event)">
                        <ul v-if="(errors && errors.municipal_account_number)">
                          <li for="municipal_account_number" class="form-label text-danger col-12"
                            v-for="error in errors.municipal_account_number">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td class="required"><label for="electrical_meter_type">Electrical Meter Type</label></td>
                      <td>
                        <select id="electrical_meter_type" class="form-select" v-model="account.electrical_meter_type">
                          <option v-for="type in elecTypes" class="form-select" :value="type.id">{{ type.type }}</option>
                        </select>

                        <ul v-if="(errors && errors.electrical_meter_type)">
                          <li for="electrical_meter_type" class="form-label text-danger col-12"
                            v-for="error in errors.electrical_meter_type">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><label class="required" for="electricity_meter_number">Electrical Meter Number</label></td>
                      <td><input id="electricity_meter_number" class="form-control" type="text"
                          v-model="account.electricity_meter_number" maxlength="12"
                          placeholder="Electrical Meter Number">
                        <ul v-if="(errors && errors.electricity_meter_number)">
                          <li for="electricity_meter_number" class="form-label text-danger col-12"
                            v-for="error in errors.electricity_meter_number">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <label class="required" for="water_meter_number">Water Meter Number</label>
                        <div><small>Required to lodge <b>Water\Sewerage</b> disputes or complaints</small></div>
                      </td>
                      <td><input id="water_meter_number" class="form-control" type="text"
                          v-model="account.water_meter_number" maxlength="12" placeholder="Water Meter Number">
                        <ul v-if="(errors && errors.water_meter_number)">
                          <li for="water_meter_number" class="form-label text-danger col-12"
                            v-for="error in errors.water_meter_number">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <label for="unit">Unit Number</label>
                      </td>
                      <td><input id="unit" class="form-control" v-model="account.unit" maxlength="256"
                          placeholder="Unit">
                        <ul v-if="(errors && errors.unit)">
                          <li for="unit" class="form-label text-danger col-12" v-for="error in errors.unit">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <label for="complex">Complex Name</label>
                      </td>
                      <td><input id="complex" class="form-control" v-model="account.complex" maxlength="256"
                          placeholder="Complex">
                        <ul v-if="(errors && errors.complex)">
                          <li for="complex" class="form-label text-danger col-12" v-for="error in errors.complex">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <label class="required" for="street_address">Street Number</label>
                        <div>
                          <small>This must be the street number reflected on your ELM account</small>
                        </div>
                      </td>
                      <td><input id="street_number" class="form-control" type="text" v-model="account.street_number"
                          maxlength="256" placeholder="Street Number">
                        <ul v-if="(errors && errors.street_number)">
                          <li for="street_number" class="form-label text-danger col-12"
                            v-for="error in errors.street_number">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <td class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <label class="required" for="street_name">Street Name</label>
                        <div>
                          <small>This must be the street name reflected on your ELM account</small>
                        </div>
                      </td>
                      <td><input id="street_name" class="form-control" type="text" v-model="account.street_name"
                          maxlength="256" placeholder="Street Name">
                        <ul v-if="(errors && errors.street_name)">
                          <li for="street_name" class="form-label text-danger col-12"
                            v-for="error in errors.street_name">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><label class="required" for="suburb">Suburb</label></td>
                      <td>
                        <select id="suburb" class="form-select" v-model="account.suburb">
                          <option v-for="sub in suburbs" class="form-select" :value="sub.suburb">{{ sub.suburb }}, {{
                            sub.postcode
                          }}</option>
                        </select>
                        <ul v-if="(errors && errors.suburb)">
                          <li for="suburb" class="form-label text-danger col-12" v-for="error in errors.suburb">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td><label class="required" for="town">Town</label></td>
                      <td>
                        <select id="town" class="form-select" v-model="account.town">
                          <option v-for="town in towns" class="form-select" :value="town.town">{{ town.town }}</option>
                        </select>
                        <ul v-if="(errors && errors.town)">
                          <li for="town" class="form-label text-danger col-12" v-for="error in errors.town">
                            {{ error }}</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button class="btn btn-primary w-100" type="submit">Update Profile</button>
              </form>
            </div>
          </div>
          <div><small class="required-tip">Required to lodge complaints and disputes</small></div>
        </div>
        <div class="tab-pane fade" id="password" role="tabpanel" aria-labelledby="password-tab">
          <div class='card shadow-lg'>
            <div class='card-header centered-content'>
              <h5>Change your password here</h5>
            </div>
            <form @submit.prevent="changePassword">
              <div class="card-body row">
                <div class="col-12 mt-2">
                  <label for="password" class="form-label">Password</label>
                  <input id="password" type="password" class="form-control" v-model="password"
                    placeholder="Please provide a strong password">
                  <ul v-if="errors">
                    <li for="password" class="form-label text-danger col-12" v-for="error in errors">
                      {{ error }}</li>
                  </ul>
                </div>
                <div class="col-12 mt-2">
                  <label for="password2" class="form-label">Confirm Password</label>
                  <input id="password2" type="password" class="form-control" v-model="password2"
                    placeholder="Retype Password">
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-primary w-100" type="submit">Update Password</button>
              </div>
            </form>
          </div>
        </div>
        <div class="tab-pane" id="municipal_account_update" role="tabpanel" aria-labelledby="municipal-tab">
          <div class='card'>
            <div class="card-header">
              <p>To request Emfuleni to send your ELM Invoices/Statements of your account 
                <br>({{ store.state.account.municipal_account_number }})
                to a specific email, use the form below:
              </p>
            </div>
            <form @submit.prevent="updateEmail">
              <div class='card-body'>
                <label for="new-password">Please review / add new email for ELM Accounts</label>
                <input id="new-password" class="form-control" type="email" v-model="newEmail" required>
              </div>
              <div class='card-footer'>
                <button class="btn btn-primary w-100">Request Email Update</button>              
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h5 {
  text-align: center;
}

img {
  height: 200px !important;
}

small {
  color: #808080;
}

.required-tip:before {
  content: "* ";
  color: red;
}

.required:after {
  content: " *";
  color: red;
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { vMaska } from 'maska'
import { reactive } from 'vue'
import { useToast } from 'vue-toastification'
let store = useStore();
let router = useRouter();
let toast = useToast();

const primary_bind = reactive({});
const alternative_bind = reactive({});

let password = ref();
let password2 = ref();
let account = ref({});
let suburbs = ref([]);
let towns = ref([]);
let elecTypes = ref([]);
let errors = ref({});
let accountChanged = ref(false);

let newEmail = ref(undefined)

onMounted(async () => {
  const suburbPromise = getSuburbs();
  const townPromise = getTowns();
  const elecTypePromise = getElectricalMeterTypes();

  await getAccountFromStore();
  newEmail.value = store.state.account.email

  Promise.all([suburbPromise, townPromise, elecTypePromise]);
});

function getAccountFromStore() {
  account.value = JSON.parse(JSON.stringify(store.state.account));  //TODO: find out a better way to copy object

  changeIdType(account.value.identification_type);
}

function accountHasChange() {
  accountChanged.value = JSON.stringify(account.value) !== JSON.stringify(store.state.account);
  // when something changes in the form, clear all errors
  errors.value = {};
}

function onIdTypeChange(event) {
  changeIdType(event.target.value, true)
}

function changeIdType(type, clearIdNumber = false) {
  if (clearIdNumber) {
    account.value.identification_number = '';
  }

  account.value.identification_type = type;
}

function handleInput(e) {
  if (account.identification_type === 'South African ID') {
    return isNumber(e);
  }
  return isAlphaNumeric(e);
}

function isNumber(e) {
  //TODO see if this can be done with regex
  // Only ASCII character in that range allowed
  const ASCIICode = (e.which) ? e.which : e.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    e.preventDefault();
  }
  else {
    return true;
  }
}

function isAlphaNumeric(e) {
  //TODO see if this can be done with regex
  const controlKeys = [
    8,  //Backspace
    9,  //Tab
    35, //End
    36, //Home
    37, //Left
    39, //Right
    46  //Delete
  ]

  const keyCode = e.keyCode == 0 ? e.charCode : e.keyCode;
  const isAlphaNum = (
    (keyCode >= 48 && keyCode <= 57)
    || (keyCode >= 65 && keyCode <= 90)
    || keyCode == 32
    || (keyCode >= 97 && keyCode <= 122)
    || (controlKeys.indexOf(e.keyCode) !== -1 && e.charCode != e.keyCode));

  if (isAlphaNum) {
    return isAlphaNum;
  }

  e.preventDefault();
}

async function getSuburbs() {
  const response = await store.dispatch('get', 'account/suburbs/');

  if (response.status === 200) {
    suburbs.value = response.data;
    return;
  }

  toast.error('Failed to retrieve suburbs', {
    timeout: 3000
  });
}

async function getTowns() {
  const response = await store.dispatch('get', 'account/towns/');

  if (response.status === 200) {
    towns.value = response.data;
    return;
  }

  toast.error('Failed to retrieve towns', {
    timeout: 3000
  });
}

async function getElectricalMeterTypes() {
  const response = await store.dispatch('get', 'account/electrical-meter-types/');

  if (response.status === 200) {
    elecTypes.value = response.data;
    return;
  }

  toast.error('Failed to retrieve meter types', {
    timeout: 3000
  });
}

async function updateProfile() {
  accountHasChange();
  if (!accountChanged.value) {
    toast.error('No changes was made to your profile, update a value', {
      timeout: 3000
    });
    return;
  }

  errors.value = {};

  sanitizePhoneNumbers();

  const data = {
    api: 'account/profile/',
    data: account.value,
  }

  const response = await store.dispatch('put', data);

  if (response.status === 400) {
    errors.value = response.data;
    return;
  }

  if (response.status === 200) {
    await store.dispatch('getProfile');

    toast.success('Successfully updated profile', {
      timeout: 3000
    });

    getAccountFromStore();

    router.push({name: 'UpdateProfileSuccess'})
    return
  }

  toast.error(`Unexpected response code from backend: ${response.status}`, {
    timeout: 3000
  });
  console.log(response.data);
}

async function changePassword() {
  if (password.value === password2.value) {
    errors.value = {};
    const payload = {
      api: 'account/password/',
      data: { password: password.value }
    }

    const response = await store.dispatch('post', payload);

    if (response.status === 400) {
      errors.value = response.data;
      return;
    }

    if (response.status === 200) {
      toast.success('Successfully updated password', {
        timeout: 3000
      });

      router.push({
        name: 'home'
      });

      return;
    }

    toast.error(`Unexpected error when updating password: ${response.status}`, {
      timeout: 3000
    });
    console.log(response.data);
  }
  else {
    errors.value['password'] = ['Password provided must match.'];
  }
}

function sanitizePhoneNumbers() {
  if (primary_bind.completed) {
    account.value.primary_contact = primary_bind.masked;
  }

  if (alternative_bind.completed) {
    account.value.alternative_contact = alternative_bind.masked;
  }
}

async function updateEmail() {
  console.log(newEmail.value);
  const payload = {
    api: 'account/new-email/',
    data: {
      email: newEmail.value
  },
  }
  const response = await store.dispatch('post', payload)
  if (response.status === 200) {
    toast.success('Successfully send email update request', {
        timeout: 10000
    });
    let el = document.getElementById('municipal_account_update')
    el.classList.remove('active')
    el.classList.add('fade')

    el = document.getElementById('profile')
    el.classList.remove('fade')
    el.classList.add('active')

    el = document.getElementById('profile-tab')
    el.classList.add('active')

    el = document.getElementById('municipal-tab')
    el.classList.remove('active')

  }
  else {
    toast.error('Could not email request, please try again later.', {
        timeout: 10000
    });
  }
}
</script>