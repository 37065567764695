<template>
    <div class="centered-content">
        <h3>Community</h3>
        <img src="./telegram-logo150-ERPA.png">
    </div>
    <hr>
    <div>
        <h3>Telegram Messaging</h3>
        <p>
            There is nothing that beats the effectiveness of a good messenger service (chat group) to get the word out, 
            fast, and we have now integrated the cream of the crop with this website (see the live feed…).
        </p>
        <p>
            ERPA evaluated multiple messenger platforms, resulting in the selection of Telegram, 
            a powerful and versatile open source messaging platform. Apart from seamless integration 
            with this website, two of the other directives in the decision-making process was 
            1) superior security, and 2) up to a maximum of 200,000 members in a single group (as opposed to only 250 in WhatsApp).
        </p>
        <p>
            Use this platform to communicate emergencies and issues relating to ELM services. 
            Click the Telegram icon to join the ERPA Community Group. This group will also receive all news articles, 
            broadcasts, etc. within seconds of them being posted on the 
            <router-link :to="{name: 'listNews'}">News</router-link>
            
            page.
        </p>
        <p>
            Go to the 
            <router-link :to="{name: 'TelegramGroups'}">Telegram Ward Groups</router-link>
            page to see all the other groups supported through this website.
        </p>
    </div>
</template>