<template>
  <div class='row'>
    <div class='col-12 centered-content'>
      <div id="create" class='card'>
        <div class='card-header'>
          <div class='d-flex'>
            <h3><span v-if="!slug">New</span><span v-else>Update</span> Campaign</h3>
            <li v-if="slug" class="ms-auto material-icons text-danger"
              @click="showModal('Delete Campaign', 'Are you sure you want to delete this campaign?', 'Yes', 'No', () => deleteCampaign())">
              delete</li>
          </div>
          <template v-if="presentation">
            <a style="text-decoration: underline;" class="link-primary" :href="presentation"
              :download="campaign.download">
              Download Current Presentation
            </a>
          </template>
        </div>
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class='card-body'>
            <div class="mb-3">
              <label for="title" class="form-label">Title</label>
              <input id="title" type="text" class="form-control" v-model="campaign.title" required />
              <label v-if="errors && errors.title" v-for="error in errors.title" for="title"
                class="form-label text-danger">{{ error }}</label>
            </div>
            <div class="mb-3">
              <label for="date" class="form-label">Date</label>
              <input id="date" type="date" class="form-control" v-model="campaign.date" required />
              <label v-if="errors && errors.date" v-for="error in errors.date" for="date"
                class="form-label text-danger">{{ error }}</label>
            </div>
            <div class="mb-3">
              <label for="attendance" class="form-label">Attendance</label>
              <input id="attendance" type="number" class="form-control" v-model="campaign.attendance" required />
              <label v-if="errors && errors.attendance" v-for="error in errors.attendance" for="editor"
                class="form-label text-danger">{{ error }}</label>
            </div>
            <div class="mb-3">
              <label for="presentation" class="form-label">Upload New Presentation</label><br>
              <input v-if="campaign && campaign.download" id="presentation" @change="storePresentation"
                class="form-control" type="file" accept="*">
              <input v-else id="presentation" @change="storePresentation" class="form-control" type="file" accept="*"
                required>
            </div>
            <div class="mb-3 form-check">
              <input id="active" type="checkbox" class="form-check-input" v-model="campaign.active" />
              <label for="active" class="form-check-label">Active Campaign</label>
              <label v-if="errors && errors.active" v-for="error in errors.active" for="editor"
                class="form-label text-danger">{{ error }}</label>
            </div>
          </div>
          <div class='card-footer'>
            <button class="btn btn-primary w-100">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'
import { showModal } from '../../components/customModal.js'

let router = useRouter();
let route = useRoute();
const toast = useToast();
const store = useStore();

let slug = ref(route.params.slug ? route.params.slug : undefined);

let campaign = ref({
  slug: slug,
  title: undefined,
  date: undefined,
  attendance: undefined,
  active: true,
});
let presentation = ref(undefined);
let errors = ref(undefined);

onMounted(async () => {
  if (slug.value) {
    await getCampaign();
  }
});

async function getCampaign() {
  const response = await store.dispatch('get', `campaigns/${slug.value}/`);
  if (response.status == 200) {
    campaign.value = response.data;
    await getPresentation();
  }
  else if (response.status == 404) {
    slug.value = undefined;
    router.push({ name: 'newCampaign' });
  }
}

async function getPresentation() {
  const response = await store.dispatch('download', `campaigns/download/${slug.value}/`);
  if (response.status == 200) {
    let blob = response.data;
    presentation.value = window.URL.createObjectURL(blob);
  }
}

async function submitForm() {
  let formData = new FormData();
  formData.append("title", campaign.value.title);
  formData.append("date", campaign.value.date);
  if (campaign.value.presentation) {
    formData.append("presentation", campaign.value.presentation);
  }
  formData.append("attendance", campaign.value.attendance);
  formData.append("active", campaign.value.active);

  let method = 'post'; //default new post
  let url = 'campaigns/admin/';
  if (slug.value) { //update
    method = 'put';
    url += `${slug.value}/`;
  }

  const payload = {
    api: url,
    data: formData
  };

  const response = await store.dispatch(method, payload);
  if (response.status == 201 || response.status == 202) {
    const msg = slug.value ? 'Updated' : 'Created'
    toast.success(`Campaign ${msg}`, {
      timeout: 5000
    });
    slug.value = response.data.slug; //in case title was updated
    errors.value = [];
    if (response.status == 202) {
      router.push({ name: 'listCampaign' });
    }
    else {
      router.push({ name: 'updateCampaign', params: { slug: response.data.slug } });
      await getCampaign();
    }
  }
  else {
    errors.value = response.data;
  }
}

async function deleteCampaign() {
  const response = await store.dispatch('delete', `campaigns/admin/${slug.value}/`);

  if (response.status == 202) {
    router.push({ name: 'listCampaign' });
  }
}

function storePresentation(event) {
  let presentation = event.target.files[0];
  campaign.value.presentation = presentation;
}
</script>