<template>
    <div>
        <h3 style="text-align: center;">Campaigns
            <router-link v-if="store.state.account && store.state.account.is_staff" class="ms-auto"
                style="text-decoration: none;" :to="{ name: 'newCampaign' }">
                <span class="material-icons text-success" style="cursor: pointer;">post_add</span>
            </router-link>
        </h3>
    </div>

    <hr>

    <div>
        <h3>ERPA Awareness Campaigns</h3>
        <p>If you want to know what ERPA has done and is doing to improve the dismal state of
            municipal affairs in Emfuleni, book your seat for the next meeting now!
            You need to know what you can do to alleviate your own plight, and how we can assist.
        </p>
    </div>

    <hr>

    <div id="next-campaigns">
        <h3>Upcoming Campaigns:</h3>
        <template v-if="campaigns && campaigns.length > 0">
            <div class='row'>
                <template v-for="campaign in campaigns">
                    <template v-if="campaign.active">
                        <div class='col-auto'>
                            <div class='card'>
                                <div class='card-header'>
                                    <h4 style="text-align: center;">{{ campaign.date }}</h4>
                                    <h6 v-if="!campaign.presentation" style="text-align: center; cursor: pointer;"
                                        @click="getPresentation(campaign)">Get Download Link</h6>
                                    <template v-if="campaign.presentation">
                                        <a style="text-decoration: underline;" class="link-primary"
                                            :href="campaign.presentation" :download="campaign.download">
                                            Download Current Presentation
                                        </a>
                                    </template>
                                </div>
                                <div class='card-body'>
                                    <h1 style="text-align: center;" class="text-primary">{{ campaign.attendance }}</h1>
                                    <h4 style="text-align: center;">{{ campaign.title }}</h4>
                                </div>
                                <div v-if="store.state.account && store.state.account.is_staff"
                                    class="card-footer centered-content">
                                    <router-link :to="{ name: 'updateCampaign', params: { slug: campaign.slug } }">Edit
                                        Campaign</router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </template>
        <template v-else>
            The next campaign will be advertised soon.
        </template>
    </div>

    <hr>

    <div id="past-campaigns" class="pt-2">
        <h3>Attendance Statistics on Past Campaigns:</h3>
        <template v-if="campaigns && campaigns.length > 0">
            <div class='row'>
                <template v-for="campaign in campaigns">
                    <template v-if="!campaign.active">
                        <div class='col-auto'>
                            <div class='card'>
                                <div class='card-header'>
                                    <h4 style="text-align: center;">{{ campaign.date }}</h4>
                                    <h6 v-if="!campaign.presentation" style="text-align: center; cursor: pointer;"
                                        @click="getPresentation(campaign)">Get Download Link</h6>
                                    <template v-if="campaign.presentation">
                                        <a style="text-decoration: underline;" class="link-primary"
                                            :href="campaign.presentation" :download="campaign.download">
                                            Download Current Presentation
                                        </a>
                                    </template>
                                </div>
                                <div class='card-body'>
                                    <h1 style="text-align: center;" class="text-primary">{{ campaign.attendance }}</h1>
                                    <h4 style="text-align: center;">{{ campaign.title }}</h4>
                                </div>
                                <div v-if="store.state.account && store.state.account.is_staff"
                                    class="card-footer centered-content">
                                    <router-link :to="{ name: 'updateCampaign', params: { slug: campaign.slug } }">Edit
                                        Campaign</router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </template>
    </div>
    <hr>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore();

let campaigns = ref(undefined);

onMounted(async () => {
    await getCampaigns();
})

async function getCampaigns() {
    const response = await store.dispatch('get', `campaigns/`);
    if (response.status == 200) {
        campaigns.value = response.data;
    }
}

async function getPresentation(campaign) {
    const response = await store.dispatch('download', `campaigns/download/${campaign.slug}/`);
    if (response.status == 200) {
        let blob = response.data;
        campaign.presentation = window.URL.createObjectURL(blob);
    }
}
</script>