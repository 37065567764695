<template>
  <div class='row' v-if="petition">
    <div class='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 pb-3'>
      <div id="signature" class="centered-content">
        <div class='card'>
          <div class='card-header'>
            <h3 style="text-align: center;">Please assist ERPA by signing this petition and reach our goal!</h3>
            <hr>
            <div id="progress" class='row mb-2'>
              <h6 style="text-align: center;"><span class="fw-bold">{{ petition.signatures }}</span> signature(s) =
                {{ petition.percentage }}% of goal</h6>
              <div class='col-2 right-content p-0'>
                <span>0</span>
              </div>
              <div class='col-8 centered-content'>
                <div class="progress w-100">
                  <div class="progress-bar" role="progressbar" :aria-valuenow="petition.percentage" aria-valuemin="0"
                    aria-valuemax="100" :style="'width: ' + petition.percentage + '%;'">
                  </div>
                </div>
              </div>
              <div class='col-2 p-0'>
                <span>{{ petition.goal }}</span>
              </div>
            </div>
          </div>
          <form @submit.prevent="submitForm">
            <div class='card-body'>
              <div class="mb-3">
                <label for="first_name" class="form-label">First Name</label>
                <input id="first_name" type="text" class="form-control" v-model="signature.first_name" />
                <label v-if="errors && errors.first_name" v-for="error in errors.first_name" for="first_name"
                  class="form-label text-danger">{{ error }}</label>
              </div>
              <div class="mb-3">
                <label for="last_name" class="form-label">Last Name</label>
                <input id="last_name" type="text" class="form-control" v-model="signature.last_name" />
                <label v-if="errors && errors.last_name" v-for="error in errors.last_name" for="last_name"
                  class="form-label text-danger">{{ error }}</label>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input id="email" type="text" class="form-control" v-model="signature.email" />
                <label v-if="errors && errors.email" v-for="error in errors.email" for="email"
                  class="form-label text-danger">{{ error }}</label>
              </div>
              <div class="mb-3">
                <label class="required" for="suburb">Suburb</label>
                <select id="suburb" class="form-select" v-model="signature.suburb">
                  <option v-for="sub in suburbs" class="form-select" :value="sub.suburb">{{ sub.suburb }}</option>
                </select>
                <ul v-if="(errors && errors.suburb)">
                  <li for="suburb" class="form-label text-danger col-12" v-for="error in errors.suburb">
                    {{ error }}</li>
                </ul>
              </div>
              <div class="mb-3">
                <label class="required" for="suburb">Town</label>
                <select id="town" class="form-select" v-model="signature.town">
                  <option v-for="town in towns" class="form-select" :value="town.town">{{ town.town }}</option>
                </select>
                <ul v-if="(errors && errors.town)">
                  <li for="town" class="form-label text-danger col-12" v-for="error in errors.town">
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div id="mail" class="mb-3 form-check">
                <input id="bcc" type="checkbox" class="form-check-input" v-model="signature.bcc" />
                <label for="bcc" class="form-check-label">Send Copy to your inbox</label>
              </div>
            </div>
            <div class='card-footer'>

              <button class="btn btn-primary w-100">Click here to sign</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8'>
      <div>
        <div class="centered-content">
          <h3>{{ petition.title }}
            <router-link v-if="store.state.account && store.state.account.is_staff" class="ms-auto"
              :to="{ name: 'updatePetition', params: { slug: slug } }">
              <li class="material-icons text-success" style="cursor: pointer;">create</li>
            </router-link>
          </h3>
        </div>

        <hr>
        <div id="editor"></div>

      </div>
    </div>

  </div>
</template>

<style scoped>
img {
  display: block !important;
  float: none !important;
}

#editor {
  border: none;
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();

let slug = route.params.slug || undefined;
let petition = ref(undefined);
let suburbs = ref([]);
let towns = ref([]);
let signature = ref({
  first_name: store.state.account ? store.state.account.first_name : undefined,
  last_name: store.state.account ? store.state.account.last_name : undefined,
  email: store.state.account ? store.state.account.email : undefined,
  suburb: store.state.account ? store.state.account.suburb : undefined,
  town: store.state.account ? store.state.account.town : undefined,
  bcc: true
});
let errors = ref([]);

onMounted(async () => {
  const petitionPromise = getPetition();
  const suburbPromise = getSuburbs();
  const townPromise = getTowns();

  await Promise.all([petitionPromise, suburbPromise, townPromise]);

  await createQuill();
})

async function getSuburbs() {
  const response = await store.dispatch('get', 'account/suburbs/');

  if (response.status === 200) {
    suburbs.value = response.data;
    return;
  }

  toast.error('Failed to retrieve suburbs', {
    timeout: 3000
  });
}

async function getTowns() {
  const response = await store.dispatch('get', 'account/towns/');

  if (response.status === 200) {
    towns.value = response.data;
    return;
  }

  toast.error('Failed to retrieve town', {
    timeout: 3000
  });
}

async function getPetition() {
  const response = await store.dispatch('get', `petitions/public/${slug}/`);
  if (response.status == 200) {
    let data = response.data;
    data.percentage = (data.signatures / data.goal * 100).toPrecision(2);
    console.log(data.body);
    data.body = JSON.parse(data.body);
    petition.value = data;
  }
  else if (response.status == 404) {
    router.push({ name: 'publicListPetition' });
  }
}

async function createQuill() {
  let quill = new Quill('#editor', {
    modules: { toolbar: false, },
    readOnly: true,
  });
  const contents = petition.value.body;
  quill.setContents(contents);
  quill.disable();
}

async function submitForm() {
  const payload = {
    api: `petitions/public/${slug}/`,
    data: signature.value
  };

  const response = await store.dispatch('post', payload);

  if (response.status == 201) {
    toast.success(`Petition successfully signed! Thank you for your time!`, {
      timeout: 5000
    });
    router.push({ name: 'listPetition' });
  }
  if (response.status == 206) {
    toast.success(`Petition successfully signed! Thank you for your time!`, {
      timeout: 5000
    });
    toast.error(`An error occurred while sending email!`, {
      timeout: 5000
    });
    router.push({ name: 'listPetition' });
  }
  else if (response.status == 409) {
    toast.error(`Petition already signed with this email!`, {
      timeout: 5000
    });
  }
  else {
    errors.value = response.data;
  }
}

</script>