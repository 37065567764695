import CreateDocument from './CreateDocument.vue'
import ListDocument from './ListDocument.vue'

const publicRoutes = [
    {path:'/documents', name: 'listDocument', component: ListDocument, meta: { title: 'Documents'}},
]
const adminRoutes = [
    {path:'/documents/create', name: 'createDocument', component: CreateDocument, meta: { title: 'Create Document', admin: true}},
]

const routes = publicRoutes.concat(adminRoutes)

export default routes