<template>
    <div class="row">
        <template v-if="complaint">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <div class='card shadow-lg'>
                    <div class='card-header'>
                        <h3>{{ complaint.complaint_type }} Complaint
                            <button v-if="complaint.active" class="btn btn-danger"
                                @click="showModal('Close Complaint', 'Are you sure you want to close the complaint?', 'Yes', 'No', () => DisableComplaint(complaint.id))">Close
                                Complaint</button>
                            <button v-else class="btn btn-success"
                                @click="showModal('Re-Open Complaint', 'Are you sure you want to re-open the complaint?', 'Yes', 'No', () => EnableComplaint(complaint.id))">Re-Open
                                Complaint</button>
                        </h3>
                        <h6>Submitted by: {{ complaint.account.first_name }} {{ complaint.account.last_name }} | {{
                            $filters.dateTime(complaint.date_modified)
                        }} </h6>
                    </div>
                    <div class='card-body'>
                        <label>{{ complaint.complaint_type }} Complaint Description:</label>
                        <p>{{ complaint.description }}</p>
                        <hr />
                        <label>Location:</label>
                        <div style="height: 300px; z-index: 0;" id="map"></div>
                        <small>Longitude: {{ complaint.longitude }}, Latitude: {{ complaint.latitude }}</small>
                        <hr />
                        <label>Images:</label>
                        <template v-if="complaint.images.length > 0">
                            <hr>
                            <div v-for="image in complaint.images">
                                <img class="w-100 mb-2" :src="image">
                            </div>
                        </template>
                        <div v-else>
                            <small>No images attached</small>
                        </div>
                        <template v-if="complaint.has_video">
                            <hr>
                            <label> Video:</label>
                            <br>
                            <button type="button" class="btn btn-primary" @click="downloadVideo(complaint.id)">
                                Download Video
                            </button>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <div class='card'>
                    <div class='card-header'>
                        <h3>Comments</h3>
                    </div>
                    <div class='card-body'>
                        <template v-if="complaint.comments.length > 0" v-for="comment in complaint.comments">
                            <div id="comment">
                                <div style="background-color: #f6f6f6;">
                                    <hr>
                                    <p>
                                        Posted By <span>{{ comment.account }}</span>
                                        on {{ $filters.dateTime(comment.date_created) }}
                                    </p>
                                    <hr>
                                </div>
                                <div>
                                    <p style="white-space: pre;">{{ comment.comment }}</p>
                                    <template v-if="comment.image">
                                        <img :src="comment.image" style="width: 100%;">
                                    </template>
                                </div>
                                <hr>
                            </div>
                        </template>
                        <template v-else>
                            Be the first to comment
                        </template>
                    </div>
                    <div class='card-footer'>
                        <form @submit.prevent="CreateComment" enctype="multipart/form-data">
                            <label class="mb-2" for="comment">Reply to complaint</label>
                            <textarea required v-model="comment" type="text" class="form-control"
                                aria-describedby="descHelp" rows="8" maxlength="1000"></textarea>
                            <br>

                            <div class="mb-3">
                                <label for="formFileMultiple" class="form-label">Upload Image</label>
                                <input @change="storeImages" class="form-control" type="file" id="formFileMultiple"
                                    accept="image/*">
                            </div>

                            <button class="btn btn-primary w-100">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            Complaint Not Found
        </template>
    </div>
</template>

<style scoped>
h3,
label,
span {
    text-align: center;
    font-weight: bold;
}
</style>
  
<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { showModal } from '../../components/customModal.js'

const toast = useToast();
const route = useRoute();
const router = useRouter();
const store = useStore();

let complaint = ref(undefined);
let comment = ref('');
let image = ref(undefined);
let id = route.params.id;
let map = null;

onMounted(async () => {
    await GetComplaint();
    createMap();
})

async function GetComplaint() {
    if (id) {
        const response = await store.dispatch(
            'get',
            store.state.account && store.state.account.is_staff ? `complaints/${id}/` : `complaints/public/${id}/`
        );
        if (response.status === 200) {
            complaint.value = response.data;
        }
        else {
            toast.error('No Complaint Found', {
                timeout: 3000
            });
            router.push({ name: 'my-complaints' });
        }
    }
}

async function CreateComment() {
    if (!comment.value) {
        toast.error('Please fill in a comment before continuing', {
            timeout: 3000
        });
    }
    let formData = new FormData();
    formData.append("comment", comment.value);
    if (image.value) {
        formData.append("image", image.value);
    }

    const payload = {
        api: `complaints/comment/${id}/`,
        data: formData
    }

    const response = await store.dispatch('post', payload);
    if (response.status == 201) {
        comment.value = '';
        window.scrollTo(0, 0);
        GetComplaint();
    }
}

async function DisableComplaint(id) {
    let reason = prompt("Please provide reason for closing the complaint", "")
    if (reason == null || reason == "") { return }
    if (id) {
        const payload = {
            api: `complaints/disable/${id}/`,
            data: { 'comment': reason }
        }
        const response = await store.dispatch('post', payload);
        if (response.status === 200) {
            GetComplaint();
        }
    }
}

async function EnableComplaint(id) {
    let reason = prompt("Please provide reason for re-opening the complaint", "")
    if (reason == null || reason == "") { return }
    if (id) {
        const payload = {
            api: `complaints/enable/${id}/`,
            data: { 'comment': reason }
        }
        const response = await store.dispatch('post', payload);
        if (response.status === 200) {
            GetComplaint();
        }
    }
}

function createMap() {
    if (map) {
        return;
    }

    map = L.map("map");

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();

    if (map.tap) {
        map.tap.disable();
    }

    map.setView([complaint.value.latitude, complaint.value.longitude], 13);
    L.marker([complaint.value.latitude, complaint.value.longitude]).addTo(map);
}

async function storeImages(event) {
    image.value = event.target.files[0];
}

async function downloadVideo(id) {
    if (id) {
        const response = await store.dispatch('download', `complaints/download/${id}/`);
        if (response.status == 200) {
            const element = document.createElement('a');
            element.setAttribute('href', window.URL.createObjectURL(response.data));
            element.setAttribute('download', `complaint_${id}_video.${response.headers.extension}`);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    }
}
</script>