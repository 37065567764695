<template v-if="store.state.account && store.state.account.is_staff">
    <div class="row centered-content">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6 col-xxl-6">
            <div class='card shadow-lg'>
                <div class='card-header'>
                    <h3>Create Poll</h3>
                </div>
                <div class="card-body">
                    <h4 class="mb-2">Title</h4>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" v-model="newPoll.title" maxlength="255"
                            placeholder="Poll Title" aria-label="Poll Title" aria-describedby="button-add-question">
                        <button class="btn btn-primary" type="button" @click="onAddQuestion"
                            id="button-add-question">New
                            Question</button>
                    </div>
                    <div class="mb-3">
                        <label for="editor" class="form-label">Editor</label>
                        <div id="editor"></div>
                        <label v-if="errors && errors.body" v-for="error in errors.body" for="editor"
                            class="form-label text-danger">{{ error }}
                        </label>
                    </div>
                    <div class="mb-2 round-border" v-for="question, idx in newPoll.questions">
                        <h5 class="mb-2" :for="'q-' + parseInt(idx + 1)">Question</h5>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" v-model="question.question" maxlength="255"
                                :id="'q-' + parseInt(idx + 1)" :placeholder="'Question ' + parseInt(idx + 1)"
                                :aria-label="'Question ' + parseInt(idx + 1)" aria-describedby="button-add-answer">
                            <button class="btn btn-primary" type="button" @click="onAddAnswer(question)"
                                id="button-add-answer">New Answer</button>
                        </div>

                        <div v-for="answer, idx in question.answers">
                            <h5 class="mb-" :for="'a-' + parseInt(idx + 1)">Answer</h5>
                            <input :id="'a-' + parseInt(idx + 1)" class="form-control" type="text"
                                v-model="answer.answer" :aria-label="'Answer ' + parseInt(idx + 1)">
                        </div>
                    </div>
                    <button class="mt-3 btn btn-primary w-100" @click="createPoll" type="button">Create Poll</button>
                    <ul v-if="errors">
                        <li for="town" class="form-label text-danger col-12" v-for="error in errors">
                            {{ error }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.round-border {
    border-radius: 10px;
    border: 1px solid black;
    padding: 20px;
}
</style>
  
<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

const store = useStore();
const router = useRouter();
const toast = useToast();

let newPoll = ref({
    title: '',
    body: '',
    questions: [
        {
            question: '',
            answers: [
                { answer: '' },
                { answer: '' },
            ]
        },

    ],
});
let errors = ref([]);

onMounted(async () => {
    createQuill()
})
async function createPoll() {
    var linkBlot = Quill.find(document.querySelector('#editor'));
    newPoll.value.body = JSON.stringify(linkBlot.getContents().ops);

    if (!store.state.account || !store.state.account.is_staff) {
        toast.error('Unauthorized', {
            timeout: 3000
        });
        return;
    }
    const payload = {
        api: 'polls/',
        data: newPoll.value
    };

    const response = await store.dispatch('post', payload);

    if (response.status === 201) {
        toast.success(`Successfully created ${response.data.slug}`, {
            timeout: 3000
        });

        errors.value = [];
        newPoll.value = {
            title: '',
            questions: [
                {
                    question: '',
                    answers: [
                        { answer: '' },
                        { answer: '' },
                    ]
                },
            ],
        };

        router.push({ name: 'detailedPoll', params: { slug: response.data.slug } });
        return;
    }

    if (response.status === 400) {
        toast.error('Failed to create poll', {
            timeout: 3000
        });

        errors.value = response.data;
        return;
    }

    toast.error(`Unexpected response: ${response.status}`, {
        timeout: 3000
    });

    console.log(response.data);
}

function onAddQuestion() {
    let q = {
        question: '',
        answers: [{ answer: '' }, { answer: '' }]
    }
    newPoll.value.questions.push(q);
}

function onAddAnswer(q) {
    q.answers.push({ answer: '' });
}


function createQuill() {
  var quill = new Quill('#editor', {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['link'],
        ['image'],
        ['video'],
        ['clean'],                                       // remove formatting button
      ],
      imageResize: {},
      videoResize: {},
    },
    theme: 'snow'
  });

  var toolbar = quill.container.previousSibling;
  toolbar.querySelector('button.ql-bold').setAttribute('title', 'bold')
  toolbar.querySelector('button.ql-italic').setAttribute('title', 'italic')
  toolbar.querySelector('button.ql-underline').setAttribute('title', 'underline')
  toolbar.querySelector('button.ql-strike').setAttribute('title', 'strike')
  toolbar.querySelector('button.ql-blockquote').setAttribute('title', 'blockquote')
  toolbar.querySelector('button.ql-code-block').setAttribute('title', 'code')
  toolbar.querySelector('button.ql-header').setAttribute('title', 'header')
  toolbar.querySelector('button.ql-list').setAttribute('title', 'list')
  toolbar.querySelector('button.ql-script').setAttribute('title', 'script')
  toolbar.querySelector('button.ql-indent').setAttribute('title', 'indent')
  toolbar.querySelector('button.ql-direction').setAttribute('title', 'direction')
  toolbar.querySelector('button.ql-header').setAttribute('title', 'header')
  toolbar.querySelector('button.ql-link').setAttribute('title', 'link')
  toolbar.querySelector('button.ql-image').setAttribute('title', 'image')
  toolbar.querySelector('button.ql-video').setAttribute('title', 'video')
  toolbar.querySelector('button.ql-clean').setAttribute('title', 'clean')

  try {
    const contents = news.value.body;
    quill.setContents(contents);
  }
  catch { }
}
</script>