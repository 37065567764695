<template>
    <div class="row centered-content">
        <template v-if="disputes && disputes.length > 0">
            <h3 style="text-align: center;">Disputes</h3>
            <hr>
            <div class="row">
                <div id="filters" class='col-12 col-sm-12 col-md-6 col-lg-4 mb-3'>
                    <div class='card'>
                        <div class='card-header p-2'>
                            <h4 class="fw-bold" style="text-transform: uppercase;">Search Disputes</h4>
                        </div>
                        <div class='card-body'>
                            <label for="title">Dispute Type</label>
                            <select class="form-select w-auto mb-3" v-model="filters.type">
                                <option value="">All</option>
                                <option v-for="t in types" :value="t.dispute_type">
                                    {{ t.dispute_type }}
                                </option>
                            </select>

                            <label class="form-check-label mb-1" for="active">Active Disputes</label>
                            <br />

                            <div class="form-control mb-3">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="activity" id="all" checked
                                        @change="changeActivityFilter">
                                    <label class="form-check-label" for="all">
                                        All
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="activity" id="active"
                                        @change="changeActivityFilter">
                                    <label class="form-check-label" for="active">
                                        Active
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="activity" id="inactive"
                                        @change="changeActivityFilter">
                                    <label class="form-check-label" for="inactive">
                                        Inactive
                                    </label>
                                </div>
                            </div>

                            <label for="title">Reason</label>
                            <input id="title" class="form-control w-auto mb-3" type="text" v-model="filters.reason">

                            <label for="user">User</label>
                            <input id="user" class="form-control w-100 mb-3" type="text" v-model="filters.user">

                            <label for="user">Date Range</label>
                            <Datepicker v-model="dateRange" range format="MM/dd/yyyy" :enable-time-picker=false />

                            <hr>

                            <div id="export">
                              <vue-excel-xlsx 
                                v-if="disputes.length > 0"
                                class="btn btn-primary w-100"
                                :data="disputes"
                                :columns="excelColumns"
                                :file-name="'Disputes'"
                                :file-type="'xlsx'"
                                :sheet-name="'All Disputes'"
                              >
                                Export
                              </vue-excel-xlsx>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="disputes" class='col-12 col-sm-12 col-md-6 col-lg-8'>
                    <template v-for="disp in disputes">
                        <template v-if="disp.dispute_type && disp.account && disp.account.first_name && disp.account.first_name">
                            <router-link v-if="
                                disp.dispute_type.includes(filters.type)
                                && (
                                    disp.reason.toLowerCase().includes(filters.reason.toLowerCase())
                                )
                                && (
                                    filters.active
                                        ? disp.active === true : filters.active === null
                                            ? disp.active === true || disp.active === false : disp.active === false
                                )
                                && (
                                    (disp.account.first_name).toLowerCase().includes(filters.user.toLowerCase())
                                    || (disp.account.last_name).toLowerCase().includes(filters.user.toLowerCase())
                                )
                                && (
                                    filterDateRange(disp)
                                )
                            " :to="{ name: 'detailDispute', params: { id: disp.id } }"
                                style="text-decoration: none; color: black;" class="">
                                <div class='card mb-3'>
                                    <div class='card-header p-2 d-flex'>
                                        <div class="left-content">
                                            <h3>{{ $filters.reference(disp.id) }} - {{ disp.dispute_type }}</h3>
                                            <small>{{ $filters.price(disp.amount_disputed) }}</small>
                                        </div>
                                        <div class="ms-auto right-content">
                                            <small>{{ disp.account.first_name }} {{ disp.account.last_name }}</small>
                                            <small>{{ disp.account.email }}</small>
                                            <small>{{ disp.account.primary_contact }}</small>
                                            <small style="font-size: 12px;">{{ disp.active ? 'Active' : 'Inactive' }}</small>
                                            <small style="font-size: 12px;">{{ $filters.dateTime(disp.date_created) }}</small>
                                        </div>
                                    </div>
                                    <div class='card-body p-2'>
                                        <p>{{ disp.reason }}</p>
                                    </div>
                                </div>
                            </router-link>
                        </template>
                        <hr>
                    </template>
                </div>
            </div>
        </template>
        <template v-else>
            No Disputes to show
        </template>
    </div>
    <div v-if="error">{{ error }}</div>
</template>

<style scoped>
small {
    font-size: 12px;
    margin: 0;
    padding: 0;
}
</style>

<script setup>
import { useToast } from 'vue-toastification'
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore();
const router = useRouter();
const toast = useToast();

let disputes = ref([]);
let error = ref(undefined);
let filters = ref({
    type: '',
    reason: '',
    active: true,
    user: ''
});
let types = ref([]);
let dateRange = ref([]);

let excelColumns = ref([
  {label: 'reference', field: "id"},
  {label: 'date', field: "date_created"},
  {label: 'first_name', field: "account.first_name"},
  {label: 'last_name', field: "account.last_name"},
  {label: 'primary_contact', field: "account.primary_contact"},
  {label: 'email', field: "account.email"},
  {label: 'account_number', field: "account.municipal_account_number"},
  {label: 'dispute_type', field: "dispute_type"},
  {label: 'amount_disputed', field: "amount_disputed"},
  {label: 'reason', field: "reason"},
  {label: 'active', field: "active"},
  {label: 'criteria_header', field: "criteria"},
])


onMounted(async () => {
    const p1 = getDisputes();
    const p2 = getTypes();

    Promise.all([p1, p2]);
});

async function getTypes() {
    const response = await store.dispatch('get', 'disputes/types/');

    if (response.status === 200) {
        types.value = response.data;
        return;
    }

    toast.error('Failed to get dispute types', {
        timeout: 3000
    });

    router.push({ name: 'home' });
}

async function getDisputes() {
    const response = await store.dispatch('get', store.state.account && store.state.account.is_staff ? 'disputes/' : 'disputes/public/');

    if (response.status === 200) {
        disputes.value = response.data;
        return;
    }

    error.value = response.data;
}

function changeActivityFilter(e) {
    if (e) {
        const target = e.target.id;

        console.log(target);
        if (target === 'active') {
            filters.value.active = true;
        } else if (target == 'inactive') {
            filters.value.active = false;
        }
        else {
            filters.value.active = null;
        }
    }
}

function filterDateRange(disp) {
    if (dateRange.value) {
        let startDate = dateRange.value[0];
        let endDate = dateRange.value[1];

        if (disp && startDate && endDate) {
            const dispDate = new Date(disp.date_created).toLocaleDateString();

            startDate = new Date(startDate).toLocaleDateString();
            endDate = new Date(endDate).toLocaleDateString();

            if (!(dispDate >= startDate && dispDate <= endDate)) {
                return false;
            }
        }
    }

    return true;
}
</script>