<template>
    <h3 style="text-align: center;">Donations</h3>
    <hr>
    <div class="card card-body">
        <h3>Thank You!</h3>
        <p>
            Every single member of the ERPA Committee is a volunteer (meaning that we don’t get paid or compensation of
            any sort),
            and we have been funding all the activities, including the development of this website,
            support to the Smart Meter crises and many others from our own pockets,
            in hard cash and sweat-equity, all in the quest to restore our community to its once flourishing splendor.
        </p>
        <p>
            Your gracious donation will go a long way to enable us to continue to support
            the technology and marketing of your Association.
        </p>
    </div>
    <div class='card my-2'>
        <div class='card-header'>
            <h3>Please complete the following form.</h3>
            <h6>
                This enables us to know who our Benefactors are.
                All donations, and the application thereof, will be fully disclosed in our Annual
                Financial Report, that will be presented at every Annual General Meeting.
            </h6>
        </div>
        <div class='card-body'>
            <div id="full_name" class="mb-3">
                <label for="full_name" class="form-label">Full Name</label>
                <input id="full_name" type="text" class="form-control" v-model="donation.full_name" />
                <label v-if="errors && errors.full_name" v-for="error in errors.full_name" for="full_name"
                    class="form-label text-danger">
                    {{ error }}
                </label>
            </div>
            <div id="email" class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input id="email" type="text" class="form-control" v-model="donation.email" />
                <label v-if="errors && errors.email" v-for="error in errors.email" for="email"
                    class="form-label text-danger">
                    {{ error }}
                </label>
            </div>
            <div id="primary_contact" class="mb-3">
                <label for="primary_contact" class="form-label">Primary Contact</label>
                <input id="primary_contact" type="tel" class="form-control" v-model="donation.primary_contact"
                    v-maska="primary_bind" data-maska="(###) ###-####" placeholder="eg. (012) 345-6789" />
                <label v-if="errors && errors.primary_contact" v-for="error in errors.primary_contact"
                    for="primary_contact" class="form-label text-danger">
                    {{ error }}
                </label>
            </div>
            <div id="category" class="mb-3">
                <label for="category" class="form-label">Category</label>
                <select v-model="donation.category" class="form-select">
                    <option v-for="category, idx in categories" :value="idx" class="form-control">
                      {{ category.suburb }} - {{ category.description }}
                    </option>
                </select>
                <label v-if="errors && errors.category" v-for="error in errors.category" for="category"
                    class="form-label text-danger">
                    {{ error }}
                </label>
            </div>
            <div id="description" class="mb-3">
                <label for="description" class="form-label">Description</label>
                <textarea id="description" type="text" class="form-control" v-model="donation.description" />
                <label v-if="errors && errors.description" v-for="error in errors.description" for="description"
                    class="form-label text-danger">
                    {{ error }}
                </label>
            </div>
            <div id="amount" class="mb-3">
                <label for="amount" class="form-label">Amount</label>
                <CurrencyInput
                    v-model="donation.amount"
                                    />
                <label v-if="errors && errors.amount" v-for="error in errors.amount" for="amount"
                    class="form-label text-danger">
                    {{ error }}
                </label>

            </div>
        </div>
        <div class='card-footer'>
            <h6>After completing the form, an email with the payment remittance will be sent to you.</h6>
            <button class="btn btn-primary" @click="showModal('Submit', 'Are you sure your details are complete?', 'Yes', 'No', () => submit())">Donate</button>
        </div>
    </div>

    <div class="card card-body mb-2">
        <div class="my-2" v-if="donation.category >= 0 && categories[donation.category].banking_details">
            <h3>Banking Information</h3>
            <li><span class="fw-bold">Bank: </span>{{ categories[donation.category].banking_details.bank.bank }}</li>
            <li><span class="fw-bold">Account Name: </span>{{ categories[donation.category].banking_details.account_holder }}</li>
            <li><span class="fw-bold">Type: </span>{{ categories[donation.category].banking_details.account_type }}</li>
            <li><span class="fw-bold">Account Number: </span>{{ categories[donation.category].banking_details.account_number }}</li>
            <li><span class="fw-bold">Branch Code: </span>{{ categories[donation.category].banking_details.bank.branch_code }}</li>
            <li><span class="fw-bold">SWIFT Code: </span>{{ categories[donation.category].banking_details.swift_code }}</li>
            <li>
              <span class="fw-bold">Confirmation: </span>
              <a :href="categories[donation.category].banking_details.bank_confirmation" download="Bank Confirmation.pdf">Download Bank Confirmation</a>
            </li>
        </div>
        <p>
            After submission, you will receive an email with a payment reference. If this reference is not used when making payment,
            ERPA will not be able to allocate the payment and these monies will be re-applied after 3 months if ERPA cannot 
            trace the amount to the owner.
        </p>
        <p>
            All donations, and the application thereof, will be fully disclosed in our Annual Financial Report,
            that will be presented at every Annual General Meeting.
        </p>
    </div>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue'
import CurrencyInput from '../../components/CurrencyInput.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { vMaska } from 'maska'
import { showModal } from '../../components/customModal.js'
import { useToast } from 'vue-toastification'
import { cloneObj } from '@/components/utils'

const toast = useToast();

const primary_bind = reactive({});

let router = useRouter();
let store = useStore();
let donation = ref({
    full_name: undefined,
    email: undefined,
    primary_contact: undefined,
    category: undefined,
    description: undefined,
    amount: undefined,
});
let errors = ref(undefined);
let categories = ref([]);

onMounted(async () => {
    if (store.state.account) {
        let account = store.state.account;
        let temp = donation.value;

        let first_name = account.first_name ? account.first_name : '';
        first_name += account.first_name && account.last_name ? ' ' : '';
        first_name += account.last_name ? account.last_name : '';
        temp.full_name = first_name;

        temp.email = account.email ? account.email : '';
        temp.primary_contact = account.primary_contact;
        donation.value = temp;
    }
    await getCategories();
})

async function getCategories() {
    const response = await store.dispatch('get', 'donations/');

    if (response.status == 200) {
        categories.value = response.data;
    }
}

async function submit() {
    sanitizePhoneNumbers();
    let obj = cloneObj(donation.value)
    // remove inserted commas from amount
    obj.category = categories.value[donation.value.category].id
    const data = {
        api: 'donations/',
        data: obj,
    }
    const response = await store.dispatch('post', data);
    if (response.status == 201 || response.status === 206) {
        if (response.status == 206) {
          toast.error(`Could not send email, please use on screen instructions.`, {
            timeout: 5000
          });
        }
        let donation = response.data
        let category = undefined
        let bank = undefined

        categories.value.forEach(cat => { //get category description
          if (cat.id === donation.category) {
            category = cat.description
            bank = cat.banking_details.bank_confirmation
          }
        })
        let queryParams = {
            id: donation.id,
            date: donation.date_created,
            full_name: donation.full_name,
            email: donation.email,
            primary_contact: donation.primary_contact,
            category: category,
            description: donation.description,
            amount: donation.amount,
            pdf: bank,
          }
        console.log(queryParams);
        router.push({ 
          name: 'DonationSuccess', 
          query: queryParams
        });
    }


}

function sanitizePhoneNumbers() {
    if (primary_bind.completed) {
        donation.value.primary_contact = primary_bind.masked;
    }
}
</script>