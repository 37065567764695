import CreateNews from './CreateNews.vue' //admin
import DetailedNews from './DetailedNews.vue'
import ListNews from './ListNews.vue'


const adminRoutes = [
    {path:'/news/new', name: 'createNews', component: CreateNews, meta: { title: 'Create News', auth: true, admin: true }},
    {path:'/news/:slug', name: 'updateNews', component: CreateNews, meta: { title: 'Update News', auth: true, admin: true }},
]

const publicRoutes = [
    {path:'/news', name: 'listNews', component: ListNews, meta: { title: 'All News' }},
    {path:'/news/detailed/:slug', name: 'detailedNews', component: DetailedNews, meta: { title: 'Detailed News' }},
]

const routes = adminRoutes.concat(publicRoutes)

export default routes