import NewDonation from './NewDonation.vue'
import DonationSuccess from './DonationSuccess.vue'
import DonationDashboard from './DonationDashboard.vue'


//no protected views
const donationRoutes = [
    {path:'/donation', name: 'NewDonation', component: NewDonation, meta: { title: 'Donate'}},
    {path:'/donation/thanks', name: 'DonationSuccess', component: DonationSuccess, meta: { title: 'Thank you!'}},
    {path:'/donation/dashboard', name: 'DonationDashboard', component: DonationDashboard, meta: { title: 'Thank you!', auth: true, admin: true}},
]

export default donationRoutes