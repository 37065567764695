<template>
    <div class="row centered-content">
        <template v-if="complaints && complaints.length > 0">
            <h3 style="text-align: center;">Complaints</h3>
            <hr>

            <div class='row'>
                <div id="filters" class='col-12 col-sm-12 col-md-6 col-lg-4 mb-3'>
                    <div class='card'>
                        <div class='card-header'>
                            <h4 class="fw-bold" style="text-transform: uppercase;">Search Complaints</h4>
                        </div>
                        <div class='card-body'>
                            <label for="type">Complaint Type</label>
                            <select id="type" class="form-select w-auto mb-3" v-model="filters.type">
                                <option value="">All</option>
                                <option v-for="t in filters.types" :value="t.complaint_type">
                                    {{ t.complaint_type }}
                                </option>
                            </select>

                            <label class="form-check-label mb-1" for="active">Active Complaints</label>
                            <br />

                            <div class="form-control">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="activity" id="all" checked
                                        @change="changeActivityFilter">
                                    <label class="form-check-label" for="all">
                                        All
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="activity" id="active"
                                        @change="changeActivityFilter">
                                    <label class="form-check-label" for="active">
                                        Active
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="activity" id="inactive"
                                        @change="changeActivityFilter">
                                    <label class="form-check-label" for="inactive">
                                        Inactive
                                    </label>
                                </div>
                            </div>

                            <br />

                            <label for="description">Description</label>
                            <input id="description" class="form-control w-100 mb-3" type="text"
                                v-model="filters.search">

                            <label for="user">User</label>
                            <input id="user" class="form-control w-100 mb-3" type="text" v-model="filters.user">

                            <label for="picker">Date Range</label>
                            <Datepicker id="picker" v-model="dateRange" range format="MM/dd/yyyy" :enable-time-picker=false />

                            
                            <hr>

                            <div id="export">
                              <vue-excel-xlsx 
                                v-if="complaints.length > 0"
                                class="btn btn-primary w-100"
                                :data="complaints"
                                :columns="excelColumns"
                                :file-name="'Complaints'"
                                :file-type="'xlsx'"
                                :sheet-name="'All Complaints'"
                              >
                                Export
                              </vue-excel-xlsx>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="complaints" class='col-12 col-sm-12 col-md-6 col-lg-8'>
                    <template v-for="comp in complaints">
                        <template v-if="
                        comp.complaint_type && comp.complaint_type.includes(filters.type)
                        && (
                            filters.active
                                ? comp.active === true : filters.active === null
                                    ? comp.active === true || comp.active === false : comp.active === false
                        )
                        && (
                            comp.description.toLowerCase().includes(filters.search.toLowerCase())
                        )
                        && (
                            (comp.account).toLowerCase().includes(filters.user.toLowerCase())
                        )
                        && (
                            filterDateRange(comp)
                        )">
                            <router-link :to="{ name: 'detailedComplaint', params: { id: comp.id } }"
                                style="text-decoration: none; color: black;">
                                <div class='card shadow mb-3'>
                                    <div class='card-header d-flex'>
                                        <h3>{{ comp.complaint_type }}</h3>
                                        <div class="ms-auto right-content">
                                            <h6 style="font-size: 12px;">{{ comp.account }}</h6>
                                            <h6 style="font-size: 12px;">{{ comp.active ? 'Active' : 'Inactive' }}</h6>
                                            <h6 style="font-size: 12px;">{{ $filters.dateTime(comp.date_created) }}</h6>
                                        </div>
                                    </div>
                                    <div class='card-body'>
                                        <p>{{ comp.description }}</p>
                                    </div>
                                </div>
                            </router-link>
                            <hr>
                        </template>
                    </template>
                </div>
            </div>
        </template>
        <template v-else>
            No Complaints to show
        </template>
    </div>
</template>
  
<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'

const store = useStore();
const toast = useToast();
const router = useRouter();

let complaints = ref([]);
let filters = ref({
    types: [],
    type: '',
    search: '',
    user: '',
    active: null
});
let dateRange = ref([]);

let excelColumns = ref([
  {label: 'id', field: "id"},
  {label: 'date', field: "date_created"},
  {label: 'account', field: "account"},
  {label: 'complaint_type', field: "complaint_type"},
  {label: 'description', field: "description"},
  {label: 'longitude', field: "longitude"},
  {label: 'latitude', field: "latitude"},
  {label: 'active', field: "active"},
])

onMounted(async () => {
    const complaintsPromise = getComplaints();
    const typesPromise = getTypes();

    Promise.all([complaintsPromise, typesPromise]);
})

async function getTypes() {
    const response = await store.dispatch(
        'get',
        'complaints/types/'
    );

    if (response.status === 200) {
        filters.value.types = response.data
        return;
    }

    toast.error('Could not load complaint types', {
        timeout: 3000
    });
    router.push({ name: 'home' });
}

async function getComplaints() {
    const response = await store.dispatch(
        'get',
        store.state.account && store.state.account.is_staff ? 'complaints/' : 'complaints/public/'
    );

    if (response.status === 200) {
        complaints.value = response.data;
        return;
    }

    toast.error('Could not load complaints', {
        timeout: 3000
    });
    router.push({ name: 'home' });

}

function changeActivityFilter(e) {
    if (e) {
        const target = e.target.id;

        if (target === 'active') {
            filters.value.active = true;
        } else if (target == 'inactive') {
            filters.value.active = false;
        }
        else {
            filters.value.active = null;
        }
    }
}

function filterDateRange(comp) {
    if (dateRange.value) {
        let startDate = dateRange.value[0];
        let endDate = dateRange.value[1];

        if (comp && startDate && endDate) {
            const compDate = new Date(comp.date_created).toLocaleDateString();

            startDate = new Date(startDate).toLocaleDateString();
            endDate = new Date(endDate).toLocaleDateString();

            if (!(compDate >= startDate && compDate <= endDate)) {
                return false;
            }
        }
    }

    return true;
}
</script>