<template>
    <div class="row centered-content">
        <div v-if="polls && polls.length > 0" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <h3 style="text-align: center;">
                Polls
                <router-link v-if="store.state.account && store.state.account.is_staff" class="ms-auto" :to="{ name: 'createPoll' }">
                    <li class="material-icons text-success" style="cursor: pointer;">post_add</li>
                </router-link>
            </h3>

            <template v-for="p in polls">
                <hr>
                <div class="card shadow">
                    <router-link class="card-header pb-1 ps-2 r-link" :to="{name: 'detailedPoll', params: {slug: p.slug}}">
                        <h4>{{ p.title }}</h4>
                    </router-link>
                    <div class="card-body d-flex p-2 ps-4">
                        <router-link class="d-block r-link" :to="{name: 'detailedPoll', params: {slug: p.slug}}">
                            <small>{{ p.active ? "Active" : "Inactive"}}</small>
                            <br>
                            <small style="font-size: 12px;">
                                Date created: {{ $filters.date(p.date_created) }}
                            </small>
                            <br>
                            <small style="font-size: 12px;">
                                Created By: {{ p.created_by }}
                            </small>
                        </router-link>
                        <div class='ms-auto' v-if="store.state.account && store.state.account.is_staff">
                            <span class="form-check form-switch mb-3" >
                                <input class="form-check-input" type="checkbox" v-model="p.active" style="cursor: pointer;"
                                    @change="changeState(p)">
                            </span>
                            <span class="material-icons text-danger"
                                @click="showModal('Delete Poll', 'Are you sure you want to delete this poll?', 'Yes', 'No', () => deletePoll(p.slug))">
                                delete
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div v-else>
            <div class='card shadow-lg'>
                <div class='card-header'>
                    <h3>Polls</h3>
                    <router-link v-if="store.state.account && store.state.account.is_staff" class="ms-auto" :to="{ name: 'createPoll' }">
                        <li class="material-icons text-success" style="cursor: pointer;">post_add</li>
                    </router-link>
                </div>
                <div class='card-body'>
                    <div class="list-group mb-1">
                        <div class='row'>
                            <h4>No polls found</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .r-link {
        text-decoration: none; 
        cursor: pointer; 
        color: black;
    }
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { showModal } from '../../components/customModal.js'

const store = useStore();
const router = useRouter();
const toast = useToast();

let polls = ref([]);

onMounted(async () => {
    await getPolls();
})

async function getPolls() {
    const response = await store.dispatch(
        'get', 
        store.state.account && store.state.account.is_staff ? 'polls/' : 'polls/public/'
        );

    if (response.status === 200) {
        polls.value = response.data;
        return;
    }

    toast.error('Could not load polls', {
        timeout: 3000
    });
    console.log(response.data);
    router.push({ name: 'home' });
}

async function deletePoll(slug) {
    if (!store.state.account || !store.state.account.is_staff) {
        toast.error('Unauthorized', {
            timeout: 3000
        });
        return;
    }
    if (slug) {
        const response = await store.dispatch('delete', `polls/${slug}/`);

        if (response.status == 202) {
            toast.success(`Successfully deleted ${slug}`, {
                timeout: 3000
            });
            await getPolls();
            return;
        }

        if (response.status == 404) {
            toast.error(`Poll ${slug} was not found`, {
                timeout: 3000
            });
            await getPolls();
            return;
        }

        toast.error(`Unexpected error deleting ${slug}`, {
            timeout: 3000
        });
        await getPolls();
        return;
    }
}

async function changeState(poll) {
    console.log(poll);
    if (!store.state.account || !store.state.account.is_staff) {
        toast.error('Unauthorized', {
            timeout: 3000
        });
        return;
    }
    if (poll) {
        const payload = {
            api: `polls/${poll.slug}/`,
            data: poll,
        }

        const response = await store.dispatch('put', payload);
        const status = poll.active ? "Activated" : "Deactivated"

        if (response.status == 200) {
            if (status == 'Activated') {
                toast.success(`${poll.slug}: ${status}`, {
                    timeout: 3000
                });
            }
            else {
                toast.info(`${poll.slug}: ${status}`, {
                    timeout: 3000
                });
            }
            await getPolls();
            return;
        }

        if (response.status == 404) {
            toast.error(`Poll ${slug} was not found`, {
                timeout: 3000
            });
            await getPolls();
            return;
        }

        toast.error(`Unexpected error changing ${poll.slug} state`, {
            timeout: 3000
        });
        await getPolls();
        return;
    }
}

</script>