
import CreatePoll from './CreatePoll.vue'
import ListPolls from './ListPolls.vue'
import DetailedPoll from './DetailedPoll.vue'

const adminRoutes = [
    //protected views
    { path: '/polls/create', name: 'createPoll', component: CreatePoll, meta: { title: 'Create Poll', auth: true, admin: true }},
    //no update view due to complexity
]

const publicRoutes = [
    //anonymous / user unprotected views
    { path: '/polls', name: 'listPolls', component: ListPolls, meta: { title: 'All Polls' } },
    { path: '/polls/:slug', name: 'detailedPoll', component: DetailedPoll, meta: { title: 'Poll Detail' } },
]

let routes = publicRoutes.concat(adminRoutes)

export default routes