<template>
    <section id="registrationForm" class="centered-content">
        <div class='card shadow-lg'>
            <div class='card-header'>
                <h3>Register your new account</h3>
                or proceed to the <router-link :to="{ name: 'login' }">login page</router-link> if you already have an
                account
            </div>
            <form @submit.prevent="submitForm">
                <div id="registrationForm" class="card-body row">
                    <div class="mb-3">
                        <label for="email" class="required form-label">Email Address</label>
                        <input id="email" type="text" class="form-control" v-model="email" placeholder="email">
                        <ul v-if="errors && errors.email">
                            <li for="email" class="form-label text-danger col-12" v-for="error in errors.email">
                                {{ error }}</li>
                        </ul>
                    </div>
                    <div class="mb-3">
                        <label for="first_name" class="required form-label">First Name</label>
                        <input id="first_name" type="text" class="form-control" v-model="first_name"
                            placeholder="first name">
                        <ul v-if="errors && errors.first_name">
                            <li for="first_name" class="form-label text-danger col-12"
                                v-for="error in errors.first_name">
                                {{ error }}</li>
                        </ul>
                    </div>
                    <div class="mb-3">
                        <label for="last_name" class="form-label">Last Name</label>
                        <input id="last_name" type="text" class="form-control" v-model="last_name"
                            placeholder="last name">
                    </div>
                    <div class="mb-3">
                        <label for="primary_contact" class="required form-label">Phone Number</label>
                        <input id="primary_contact" class="form-control" type="tel" v-model="primary_contact" v-maska
                            data-maska="(###) ###-####" placeholder="eg. (012) 345-6789">
                        <ul v-if="errors && errors.primary_contact">
                            <li for="primary_contact" class="form-label text-danger col-12"
                                v-for="error in errors.primary_contact">
                                {{ error }}</li>
                        </ul>
                    </div>

                    <label for="password" class="form-label">Password</label><br>
                    <div class="input-group mb-3">
                        <input :type="inputPassword" class="form-control" v-model="password" aria-label="password"
                            placeholder="Please provide a strong password">
                        <span class="input-group-text material-icons" @click="toggleShow">{{ icon }}</span>
                        <ul v-if="errors && errors.password2">
                            <li for="password2" class="form-label text-danger col-12" v-for="error in errors.password2">
                                {{ error }}
                            </li>
                        </ul>
                    </div>

                    <label for="password2" class="form-label">Confirm Password</label><br>
                    <div class="input-group mb-3">
                        <input :type="inputPassword" class="form-control" v-model="password2" aria-label="password2"
                            placeholder="Retype Password">
                        <span class="input-group-text material-icons" @click="toggleShow">{{ icon }}</span>
                        <ul v-if="errors && errors.password2">
                            <li for="password2" class="form-label text-danger col-12" v-for="error in errors.password2">
                                {{ error }}
                            </li>
                        </ul>
                    </div>
                    <div class="form-check" style="padding-left: 35px;">
                        <input class="form-check-input" type="checkbox" v-model="terms_accepted" id="acceptCheck">
                        <label class="form-check-label" for="acceptCheck">
                            I accept the registration terms below.
                        </label>
                        <ul v-if="errors && errors.terms">
                            <li for="acceptCheck" class="form-label text-danger col-12" v-for="error in errors.terms">
                                {{ error }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                    <button class="btn btn-primary w-100" type="submit">Create Account</button>
                </div>
            </form>
        </div>
    </section>

    <div><small class="required-tip">Required to create a profile</small></div>
    <section class="mt-5">
        <RegisterDisclaimer />
    </section>
</template>

<style scoped>
small {
    color: #808080;
}

.required-tip:before {
    content: "* ";
    color: red;
}

.required:after {
    content: " *";
    color: red;
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { vMaska } from 'maska'
import { useToast } from 'vue-toastification'
import RegisterDisclaimer from '../Core/RegisterDisclaimer'

const toast = useToast();

let store = useStore();
let router = useRouter();
let inputPassword = ref('password');
let icon = ref('visibility');
let email = ref();
let first_name = ref();
let last_name = ref();
let primary_contact = ref();
let password = ref();
let password2 = ref();
let errors = ref({});
let terms_accepted = ref(false);

onMounted(async () => {
    if (store.state.isAuthenticated) {
        toast.info('You are already signed into your account.', {
            timeout: 3000
        });
        router.push({ 'name': 'home' });
    }
})

async function submitForm() {
    console.log(terms_accepted.value);
    errors.value = {};
    if (!terms_accepted.value) {
        errors.value['terms'] = ['Please accepts registration terms.'];
        return;
    }

    if (password.value === password2.value) {
        const payload = {
            api: 'account/profile/',
            data: {
                "email": email.value,
                "first_name": first_name.value,
                "last_name": last_name.value,
                "primary_contact": primary_contact.value,
                "password": password.value
            }
        }

        const response = await store.dispatch('post', payload);

        if (response.status === 400) {
            errors.value = response.data;
            return;
        }

        if (response.status === 201) {
            //TODO cant we automatically log the user in?
            toast.success('Successfully created account', {
                timeout: 3000
            });
            router.push({
                name: 'login',
                query: {
                    "email": email.value,
                }
            });

            return;
        }

        toast.error(`Unexpected error when creating account: ${response.status}`, {
            timeout: 3000
        });
    }
    else {
        errors.value['password'] = ['Password provided must match.'];
    }
}

function toggleShow() {
    inputPassword.value = inputPassword.value === 'text' ? 'password' : 'text';
    icon.value = inputPassword.value === 'text' ? 'visibility_off' : 'visibility';
}
</script> 