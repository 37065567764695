import ListComplaints from './ListComplaints.vue'
import LodgeComplaint from './LodgeComplaint.vue'
import DetailedComplaint from './DetailedComplaint.vue'

const complaintRoutes = [
    { path: '/complaint', name: 'lodgeComplaint', component: LodgeComplaint, meta: { title: 'Complaint', auth: true } },
    { path: '/complaints', name: 'listComplaints', component: ListComplaints, meta: { title: 'Lodged Complaints', auth: true } },
    { path: '/complaints/:id', name: 'detailedComplaint', component: DetailedComplaint, meta: { title: 'Complaint Details', auth: true } },
]

export default complaintRoutes