<template>
  <div class='row'>
    <div class='col-12'>
      <div id="create" class='card'>
        <div class='card-header d-flex'>
          <h3><span v-if="!slug">New</span><span v-else>Update</span> News Article</h3>
          <li v-if="slug" class="ms-auto material-icons text-danger" @click="showModal('Delete Article', 'Are you sure you want to delete this news article?', 'Yes', 'No', () => deleteNews())">delete</li>
        </div>
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class='card-body'>

            <div class="mb-3">
              <label for="title" class="form-label">Title</label>
              <input id="title" type="text" class="form-control" v-model="news.title" placeholder="title" />
              <label v-if="errors && errors.title" v-for="error in errors.title" for="title"
                class="form-label text-danger">{{ error }}</label>
            </div>
            <div class="mb-3">
              <label for="editor" class="form-label">Editor</label>
              <div id="editor"></div>
              <label v-if="errors && errors.body" v-for="error in errors.body" for="editor"
                class="form-label text-danger">{{ error }}</label>
            </div>
            <div id="image_uploader" class="mb-3">
              <template v-if="profile_image">
                <label for="formFileMultiple" class="form-label">Current Thumbnail</label><br>
                <img :src="profile_image" style="max-width: 400px;">
                <br>
                <br>
                <label for="formFileMultiple" class="form-label">Upload Thumbnail</label><br>
                <input id="profile_image" @change="storeImage" class="form-control" type="file" accept="image/*">
              </template>
              <template v-else>
                <label for="formFileMultiple" class="form-label">Upload Thumbnail</label><br>
                <input id="profile_image" @change="storeImage" class="form-control" type="file" accept="image/*">
              </template>
              <label v-if="errors && errors.profile_image" v-for="error in errors.profile_image" for="editor"
                class="form-label text-danger">{{ error }}</label>
            </div>
            <div class="mb-3 form-check">
              <input id="published" type="checkbox" class="form-check-input" v-model="news.published"
                placeholder="title" />
              <label for="published" class="form-check-label">Published</label>
              <label v-if="errors && errors.published" v-for="error in errors.published" for="editor"
                class="form-label text-danger">{{ error }}</label>
            </div>
          </div>
          <div class='card-footer'>
            <button class="btn btn-primary w-100">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<style scoped>
  label {
    font-weight: bold;
  }
  .ql-editor iframe {
    pointer-events: none !important;
  }
</style>

<script setup>
import { onMounted, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import { showModal } from '../../components/customModal.js'

const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();

let slug = ref(route.params.slug ? route.params.slug : undefined);
let news = ref({
  title: '',
  body: '',
  published: true,
});
let errors = ref(undefined);
let profile_image = ref(undefined);

onMounted(async () => {
  if (slug.value) {
    await getNews();
  }
  createQuill();
})

async function getNews() {
  if (!slug.value) { return }
  const response = await store.dispatch('get', `/news/${slug.value}/`);
  if (response.status == 200) {
    news.value = response.data;
    profile_image.value = news.value.profile_image;
    news.value.profile_image = '';
    news.value.body = JSON.parse(news.value.body);
  }

  else if (response.status == 404) {
    router.push({ name: 'listNews' });
  }
}

async function submitForm() {
  var linkBlot = Quill.find(document.querySelector('#editor'));
  news.value.body = JSON.stringify(linkBlot.getContents().ops);

  let formData = new FormData();
  formData.append("title", news.value.title);
  formData.append("body", news.value.body);
  formData.append("published", news.value.published);
  if (news.value.profile_image && news.value.profile_image != '') {
    formData.append("profile_image", news.value.profile_image);
  }
  let method = '';
  let url = '/news/';
  if (slug.value) {
    //update
    method = 'put';
    url += `${slug.value}/`;
    formData.append("slug", slug.value);
  }
  else {
    //new
    method = 'post';
  }
  const payload = {
    api: url,
    data: formData
  };

  const response = await store.dispatch(method, payload);
  if (response.status == 200 || response.status == 201) {
    const msg = slug.value ? 'Updated' : 'Created';
    toast.success(`News ${msg}`, {
      timeout: 5000
    });
    slug.value = response.data.slug;
    router.push({ name: 'detailedNews', params: { slug: response.data.slug } });
    await getNews();
  }
  else {
    errors.value = response.data;
  }
}

function createQuill() {
  var quill = new Quill('#editor', {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['link'],
        ['image'],
        ['video'],
        ['clean'],                                       // remove formatting button
      ],
      imageResize: {},
      videoResize: {},
    },
    theme: 'snow'
  });

  var toolbar = quill.container.previousSibling;
  toolbar.querySelector('button.ql-bold').setAttribute('title', 'bold')
  toolbar.querySelector('button.ql-italic').setAttribute('title', 'italic')
  toolbar.querySelector('button.ql-underline').setAttribute('title', 'underline')
  toolbar.querySelector('button.ql-strike').setAttribute('title', 'strike')
  toolbar.querySelector('button.ql-blockquote').setAttribute('title', 'blockquote')
  toolbar.querySelector('button.ql-code-block').setAttribute('title', 'code')
  toolbar.querySelector('button.ql-header').setAttribute('title', 'header')
  toolbar.querySelector('button.ql-list').setAttribute('title', 'list')
  toolbar.querySelector('button.ql-script').setAttribute('title', 'script')
  toolbar.querySelector('button.ql-indent').setAttribute('title', 'indent')
  toolbar.querySelector('button.ql-direction').setAttribute('title', 'direction')
  toolbar.querySelector('button.ql-header').setAttribute('title', 'header')
  toolbar.querySelector('button.ql-link').setAttribute('title', 'link')
  toolbar.querySelector('button.ql-image').setAttribute('title', 'image')
  toolbar.querySelector('button.ql-video').setAttribute('title', 'video')
  toolbar.querySelector('button.ql-clean').setAttribute('title', 'clean')

  try {
    const contents = news.value.body;
    quill.setContents(contents);
  }
  catch { }
}

function storeImage(event) {
  let image = event.target.files[0];
  news.value.profile_image = image;
}

async function deleteNews() {
  const response = await store.dispatch('delete', `news/${slug.value}/`);

  if (response.status == 202) {
    router.push({ name: 'listNews' });
  }
}
</script>